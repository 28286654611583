import { useRouter } from 'next/router';
import { experimentDetails } from '../new-lib/experiments';

export const useCleanPath = (): string => {
  const { asPath } = useRouter();
  const knownSegments = new Set([
    ...experimentDetails.variations,
    'desktop',
    'mobile',
    'app',
    'web'
  ]);

  // Split the path into segments and filter out empty ones
  const parts = asPath.split('/').filter(Boolean);

  // Remove known segments from the start
  while (parts.length > 0 && knownSegments.has(parts[0])) {
    parts.shift();
  }

  // Return the cleaned-up path
  return `/${parts.join('/')}`;
};
