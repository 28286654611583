import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import SearchIcon from '../../../../new-components/svg-icons/SearchIcon';
import { getImagePath } from '../../../../helpers/common';
import ThinArrowIcon from '../../../../new-components/svg-icons/ThinArrowIcon';
import { useRouter } from 'next/router';
import ImageWrapper from '../../../../new-components/ImageWrapper';

export enum CategoryTypes {
  COMPOUND = 'compound',
  DEVELOPER = 'developer',
  AREA = 'area',
  LAUNCH = 'new project'
}

export interface SearchInputResult {
  name: string;
  category: CategoryTypes;
  id: number;
  slug?: string;
  image_path?: string;
  area_name?: string;
  prefix?: string;
  fuzzy_tag?: string;
  compound_id?: number;
  parent_compound_id?: number;
}

interface ResultInterface {
  result: SearchInputResult;
  handleSelect: (selection: SearchInputResult) => void;
}

interface LocationResultsProps {
  searchResults: SearchInputResult[];
  handleSelect: (selection: SearchInputResult) => void;
}

interface ResultProps {
  locale?: string;
}

const Result = ({ result, handleSelect }: ResultInterface) => {
  const { t } = useTranslation('common');
  const { locale } = useRouter();

  if (
    result.category == CategoryTypes.AREA ||
    result.category == CategoryTypes.DEVELOPER ||
    result.category == CategoryTypes.LAUNCH
  ) {
    return (
      <ResultContainer
        onClick={() => handleSelect(result)}
        data-cy="search-input-results"
      >
        <div className="result-left">
          <div className="icon-side">
            <SearchIcon />
          </div>
          <div className="text-3">{result?.name}</div>
        </div>

        <div className="result-right">
          <span>{t(result?.category)}</span>
        </div>
      </ResultContainer>
    );
  } else {
    return (
      <ResultContainer
        className="compound-result"
        locale={locale}
        onClick={() => handleSelect(result)}
        data-cy="search-input-results"
      >
        <div className="result-left">
          <div className="image-side">
            <ImageWrapper
              src={getImagePath(result?.image_path)}
              alt="compound-image"
              fill
              objectFit="cover"
              imageSize="vLow"
              sizes="30vw"
            />
          </div>
          <div className="text-3">
            {result?.name}
            <span className="text-2 location">{result?.area_name}</span>
          </div>
        </div>

        <div className="result-right">
          {result?.fuzzy_tag === 'cityscape' && (
            <div className="cityscape-label">{`${t('offer')}`}</div>
          )}

          <ThinArrowIcon />
        </div>
      </ResultContainer>
    );
  }
};

const SearchLocationResults = ({
  searchResults,
  handleSelect
}: LocationResultsProps) => {
  return (
    <Container>
      {searchResults?.map(result => (
        <Result result={result} key={result?.id} handleSelect={handleSelect} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .headline-2 {
    margin-bottom: 12px;
  }

  @media screen and (max-width: ${props =>
      props.theme.breakPoints.mobile.max}) {
    margin-bottom: 100px;
  }
`;

const ResultContainer = styled.div<ResultProps>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;
  &:not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
  }
  .result-left {
    display: flex;
    align-items: center;
    min-width: 0;
  }

  .text-3 {
    color: ${props => props.theme.light.colors.black};
    font-size: 14px;
    font-family: materialMedium;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-inline-start: 5px;
  }

  .result-right {
    display: flex;
    align-items: center;
    text-align: center;
  }

  .cityscape-label {
    border: 1px solid transparent;
    border-radius: 35px;
    padding: 3px 15px;
    background-color: #ff9108;
    color: white;
    font-size: ${props => props.theme.font.sizes.xxs};
    font-family: materialMedium;
    text-align: center;
    white-space: nowrap;
    margin-inline-end: 8px;
  }
  .icon-side {
    min-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 19px;
      height: 19px;
      path {
        fill: #646464;
      }
    }
  }

  span:not(.location) {
    color: ${props => props.theme.light.colors.black};
    background-color: #f0f0f0;
    padding: 2.5px 8px;
    font-size: ${props => props.theme.font.sizes.xxs};
    font-family: materialMedium;
    min-width: fit-content;
    border-radius: 35px;
    text-transform: capitalize;
  }

  &.compound-result {
    height: 58px;
    span {
      border-radius: 4px;
    }

    .image-side {
      position: relative;
      min-width: 40px;
      height: 40px;
    }

    span.location {
      font-size: 11px;
      color: #646464;
      display: block;
    }

    svg {
      height: 8px;
      width: 6px;
      transform: ${props => (props.locale == 'en' ? 'rotate(180deg)' : '')};
      margin-inline-end: 8px;
      path {
        stroke: #666666;
      }
    }
  }

  &:hover {
    background-color: rgba(118, 173, 225, 0.08);
  }
`;
export default SearchLocationResults;
