import ImageWrapper from '../ImageWrapper';

const InstagramIcon = () => {
  return (
    <ImageWrapper
      src={`/assets/icons/new/instagram-icon.svg`}
      alt={'instagram-icon'}
      width={30}
      height={30}
    />
  );
};
export default InstagramIcon;
