import ImageWrapper from '../ImageWrapper';

const AgentIcon = () => {
  return (
    <ImageWrapper
      src={`/assets/icons/new/agent-icon.svg`}
      alt={'agent-icon'}
      width={24}
      height={25}
    />
  );
};

export default AgentIcon;
