import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { i18n } from 'next-i18next';
import { ApiOptions } from './api-options';
import { LANGUAGE } from '../constants/i18n-constants';
import Cookies from 'universal-cookie';
import { PHASE_PRODUCTION_BUILD } from 'next/constants';
import { Environments } from '../Environments';
import { Helpers } from '../utilities/helpers';

const nextServerInstance = axios.create({
  baseURL: Environments.HOST
});
const clientInstance = axios.create({ baseURL: Environments.PUBLIC_API });
const newClientInstance = axios.create({
  baseURL: Environments.PUBLIC_NEW_API
});

const ssrInstance = (options?: ApiOptions): AxiosInstance => {
  return axios.create({
    baseURL:
      process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD
        ? Environments.PUBLIC_API
        : Environments.INTERNAL_API,
    headers: {
      'Accept-Language':
        options?.ssrLocale ?? i18n?.language ?? LANGUAGE.ENGLISH,
      platform: 'web'
    }
  });
};
const newSsrInstance = (options?: ApiOptions): AxiosInstance => {
  return axios.create({
    baseURL:
      process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD
        ? Environments.PUBLIC_NEW_API
        : Environments.INTERNAL_NEW_API,
    headers: {
      'Accept-Language':
        options?.ssrLocale ?? i18n?.language ?? LANGUAGE.ENGLISH,
      platform: 'web',
      ...(options?.source ? { source: options.source } : {})
    }
  });
};

const requestInterceptor = async (
  config: InternalAxiosRequestConfig,
  options?: ApiOptions
): Promise<InternalAxiosRequestConfig> => {
  config.headers['Accept-Language'] =
    options?.ssrLocale ?? i18n?.language ?? LANGUAGE.ENGLISH;
  config.headers['client-id'] = new Cookies().get('clientID', {
    doNotParse: true
  });
  config.headers.platform = 'web';
  return config;
};

const responseInterceptor = async (error: any) => {
  if (error?.response) {
    // The request was made and the server responded with a status code outside 2xx
    // eslint-disable-next-line
    console.error('API Failed:', {
      url: error?.config?.url,
      method: error?.config?.method,
      status: error?.response?.status,
      data: error?.response?.data
    });
  } else if (error?.request) {
    // The request was made but no response was received
    // eslint-disable-next-line
    console.error('No Response:', {
      url: error?.config?.url,
      method: error?.config?.method,
      error: 'No response received from server'
    });
  } else {
    // Something happened in setting up the request
    // eslint-disable-next-line
    console.error('Request Error:', error?.message);
  }
  return Promise.reject(error);
};

nextServerInstance.interceptors.request.use(
  config => requestInterceptor(config),
  error => Promise.reject(error)
);
clientInstance.interceptors.request.use(
  config => requestInterceptor(config),
  error => Promise.reject(error)
);
newClientInstance.interceptors.request.use(
  config => requestInterceptor(config),
  error => Promise.reject(error)
);

export const nextServerApi = (options?: ApiOptions): AxiosInstance => {
  return nextServerInstance;
};

export const oldApi = (options?: ApiOptions): AxiosInstance => {
  return Helpers.isSsr() ? ssrInstance(options) : clientInstance;
};
export const newApi = (options?: ApiOptions): AxiosInstance => {
  return Helpers.isSsr() ? newSsrInstance(options) : newClientInstance;
};
