import React from 'react';
import styled from 'styled-components';
import NumberInput from '../../../../../new-components/NumberInput';
import { InputOption } from '../../../../../new-lib/types/search-filters';
import { useTranslation } from 'next-i18next';

interface ChildProps {
  minList?: InputOption[];
  maxList?: InputOption[];
  handleMin: (e: React.FormEvent<HTMLInputElement>) => void;
  handleMax: (e: React.FormEvent<HTMLInputElement>) => void;
  minState: number | undefined;
  maxState: number | undefined;
  min: number;
}

const MinMaxFilterPresentation = ({
  handleMin,
  handleMax,
  minList,
  maxList,
  minState,
  maxState,
  min
}: ChildProps): JSX.Element => {
  const { t } = useTranslation('search');
  return (
    <>
      <Container>
        <NumberInput
          placeholder={t('min')}
          width={130}
          onSelect={handleMin}
          onBlur={handleMin}
          list={minList}
          name={'min'}
          initValue={minState}
          min={min}
        />
        <NumberInput
          placeholder={t('max')}
          width={130}
          onSelect={handleMax}
          onBlur={handleMax}
          list={maxList}
          name={'max'}
          initValue={maxState}
          min={min}
        />
      </Container>
    </>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
export default MinMaxFilterPresentation;
