export enum LANGUAGE {
  ENGLISH = 'en',
  ARABIC = 'ar',
}

export enum DIRECTION {
  LTR = 'ltr',
  RTL = 'rtl',
}

export const LANGUAGE_LABEL: { [key in LANGUAGE]: string } = {
  [LANGUAGE.ARABIC]: 'العربية',
  [LANGUAGE.ENGLISH]: 'English',
};

export const LANGUAGE_DIRECTION: { [key in LANGUAGE]: DIRECTION } = {
  [LANGUAGE.ENGLISH]: DIRECTION.LTR,
  [LANGUAGE.ARABIC]: DIRECTION.RTL,
};

export const LANGUAGE_ISO_LOCALE: { [key in LANGUAGE]: string } = {
  [LANGUAGE.ENGLISH]: 'en-US',
  [LANGUAGE.ARABIC]: 'ar-EG',
};

export class Language {
  public static fromString(language?: string): LANGUAGE {
    if (language && language === 'ar') {
      return LANGUAGE.ARABIC;
    }
    return LANGUAGE.ENGLISH;
  }
}
