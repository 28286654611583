import {
  DIRECTION,
  Language,
  LANGUAGE,
  LANGUAGE_DIRECTION
} from '../constants/i18n-constants';

export class LanguageUtils {
  public static dir = (
    locale: string | LANGUAGE | undefined
  ): 'rtl' | 'ltr' => {
    return locale === LANGUAGE.ARABIC ? 'rtl' : 'ltr';
  };

  public static isArabic = (locale: string | LANGUAGE | undefined): boolean => {
    return locale === LANGUAGE.ARABIC;
  };

  public static isLTR = (locale: string | LANGUAGE | undefined): boolean => {
    return LANGUAGE_DIRECTION[Language.fromString(locale)] === DIRECTION.LTR;
  };

  public static isRTL = (locale: string | LANGUAGE | undefined): boolean => {
    return LANGUAGE_DIRECTION[Language.fromString(locale)] === DIRECTION.RTL;
  };
}
