import styled from 'styled-components';
import Link from 'next/link';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

// Components
import GradientLine from '../../new-components/ui/GradientLine';

// Assets
import nawyIcon from '../../public/assets/icons/common/nawy.svg';
import FacebookIcon from '../../new-components/svg-icons/FacebookIcon';
import LinkedinIcon from '../../new-components/svg-icons/LinkedinIcon';
import TwitterIcon from '../../new-components/svg-icons/TwitterIcon';
import YoutubeIcon from '../../new-components/svg-icons/YoutubeIcon';
import InstagramIcon from '../../new-components/svg-icons/InstagramIcon';
import DownloadApp from '../ui/DownloadApp';
import { theme } from '../../styles/theme';
import {
  FooterLinkEntity,
  FooterLinksDTO,
  PropertiesFooterLinkEntity
} from '../../new-lib/dtos';

interface FooterMobileProps {
  footerLinks: FooterLinksDTO;
  getLinkRef: (
    item: FooterLinkEntity | PropertiesFooterLinkEntity,
    linkTo: string
  ) => string;
  getFooterLinks: () => JSX.Element[];
  handleItemClick: (itemName: string) => void;
}

const FooterMobile: React.FC<FooterMobileProps> = props => {
  const { t } = useTranslation('common');

  return (
    <FooterContainer id="footer">
      <FooterLinksContainer>
        {props.getFooterLinks()}
        <Link href="/" prefetch>
          <Image
            src={nawyIcon}
            alt="Nawy Icon"
            onClick={() => props.handleItemClick('nawy_logo_click')}
            style={{ width: 'auto', height: 'auto', objectFit: 'cover' }}
          />
        </Link>
        <p className="footerTitle">{t('real_estate_company')}</p>
        <div className="socialIcons">
          <Link
            prefetch={false}
            href="https://www.facebook.com/nawyrealestate"
            target="_blank"
            rel="noreferrer"
            onClick={() => props.handleItemClick('facebook_click')}
            aria-label="facebook-link"
          >
            <FacebookIcon />
          </Link>
          <Link
            prefetch={false}
            href="https://www.linkedin.com/company/nawyestate/mycompany/"
            target="_blank"
            rel="noreferrer"
            onClick={() => props.handleItemClick('linkedin_click')}
            aria-label="linkedin-link"
          >
            <LinkedinIcon />
          </Link>
          <Link
            prefetch={false}
            href="https://www.youtube.com/channel/UCAtydzjr9JcWM4UNXU1AXig"
            target="_blank"
            rel="noreferrer"
            onClick={() => props.handleItemClick('youtube_click')}
            aria-label="youtube-link"
          >
            <YoutubeIcon />
          </Link>
          <Link
            prefetch={false}
            href="https://www.instagram.com/nawyrealestate/"
            target="_blank"
            rel="noreferrer"
            onClick={() => props.handleItemClick('instagram_click')}
            aria-label="instagram-link"
          >
            <InstagramIcon />
          </Link>
          <Link
            prefetch={false}
            href="https://x.com/nawyegypt"
            target="_blank"
            rel="noreferrer"
            onClick={() => props.handleItemClick('twitter_click')}
            aria-label="twitter-link"
          >
            <TwitterIcon />
          </Link>
        </div>
        <h6 className="download">{t('download_our_application')}</h6>
        <DownloadApp
          iconColor={theme.light.colors.darkGrey}
          iconWidth="auto"
          iconHeight="32px"
          eventLocation="Footer"
        />
        <GradientLine height={'2px'} />
        <span dir="ltr" className="copyright" itemProp="copyright">
          ©Copyright 2025 - Nawy.
        </span>
      </FooterLinksContainer>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 16px 16px;
  gap: 8px;
  width: 100%;
  height: auto;
  background: linear-gradient(326.12deg, #ffffff 21.79%, #e4ebf2 88.58%);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: 20px;
`;
const FooterLinksContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  .footerTitle {
    font-family: materialMedium;
    font-size: ${props => props.theme.font.sizes.l};
    color: ${props => props.theme.light.colors.darkGrey};
    align-self: center;
  }
  .socialIcons {
    display: flex;
    align-self: center;
    gap: 8px;
    padding: 6px 0px;
  }
  .download {
    font-family: materialMedium;
    font-size: ${props => props.theme.font.sizes.xs};
    color: ${props => props.theme.light.colors.darkGrey};
    align-self: center;
  }
  .socialApps {
    display: flex;
    align-self: center;
    gap: 6px;
  }
  .copyright {
    font-family: materialMedium;
    font-size: ${props => props.theme.font.sizes.m};
    color: ${props => props.theme.light.colors.black};
    align-self: center;
    padding: 8px 0px;
  }
`;

export default FooterMobile;
