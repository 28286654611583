import ImageWrapper from '../ImageWrapper';

const LinkedinIcon = () => {
  return (
    <ImageWrapper
      src={`/assets/icons/new/linkedin-icon.svg`}
      alt={'linkedin-icon'}
      width={30}
      height={30}
    />
  );
};
export default LinkedinIcon;
