import styled from 'styled-components';
import { SearchFiltersTypes } from '../../../../reducers/search-filters/reducer';
import { useTranslation } from 'next-i18next';
import RangeFilterContainer from './range-filter/RangeFilterContainer';
import SelectFilterContainer from './select-filter/SelectFilterContainer';
import { useContext } from 'react';
import {
  SearchContext,
  SearchContextInterface
} from '../../context/SearchContext';
import { resetFilter } from '../../../../helpers/search-filters';
import { useRouter } from 'next/router';
import DrawerFilterContainer from './menu-select-filter/DrawerFilterContainer';
import NawyNowFilter from './nawy-now-filter/NawyNowFilter';
import PaymentFilter from './payment-filter/PaymentFilter';
import DeliveryFilter from './delivery-filter/DeliveryFilter';
import { isEqual } from '../../../../helpers/isEqual';

export enum FilterStyles {
  CHECKBOX = 'checkbox',
  NUMBER_SELECT = 'number-select',
  RANGE = 'range',
  CHIP_SELECT = 'chip-select',
  RADIO_BUTTON = 'radio-button',
  DRAWER_SELECT = 'menu-select',
  NAWY_NOW = 'nawy-now',
  PAYMENT = 'payment',
  DELIVERY = 'delivery'
}

interface ContainerProps {
  filterName: string;
  withReset?: boolean;
  filterStyle: FilterStyles;
  filterType:
    | SearchFiltersTypes
    | { delivery_date: SearchFiltersTypes; delivery_years: SearchFiltersTypes }
    | { min: SearchFiltersTypes; max: SearchFiltersTypes }
    | {
        down_payment: SearchFiltersTypes;
        monthly_installments: SearchFiltersTypes;
        installment_years: SearchFiltersTypes;
      };
  filterOptions: any;
  prefix?: string;
  step?: number;
  withSeeMore?: boolean;
  singleSelect?: boolean;
}

const FilterWrapper = ({
  filterName,
  withReset,
  filterStyle,
  filterOptions,
  filterType,
  prefix,
  step,
  withSeeMore,
  singleSelect
}: ContainerProps) => {
  const { searchFiltersDispatch, searchFiltersState } = useContext(
    SearchContext
  ) as SearchContextInterface;
  const { t } = useTranslation('common');
  const router = useRouter();
  const notSearchFilters = !router.asPath.includes('/search');

  const getFilterComponent = () => {
    switch (filterStyle) {
      case FilterStyles.DRAWER_SELECT:
        return (
          <DrawerFilterContainer
            filterOptions={filterOptions}
            filterType={filterType as SearchFiltersTypes}
            filterName={filterName}
          />
        );
      case FilterStyles.RANGE:
        return (
          <RangeFilterContainer
            filterOptions={filterOptions}
            filterType={
              filterType as { min: SearchFiltersTypes; max: SearchFiltersTypes }
            }
            prefix={prefix}
            step={step}
          />
        );
      case FilterStyles.CHECKBOX:
      case FilterStyles.NUMBER_SELECT:
      case FilterStyles.CHIP_SELECT:
      case FilterStyles.RADIO_BUTTON:
        return (
          <SelectFilterContainer
            filterOptions={filterOptions}
            filterType={filterType as SearchFiltersTypes}
            filterName={filterName}
            filterStyle={filterStyle}
            withSeeMore={withSeeMore}
            singleSelect={singleSelect}
          />
        );
      case FilterStyles.NAWY_NOW:
        return <NawyNowFilter />;
      case FilterStyles.PAYMENT:
        return (
          <PaymentFilter
            filterOptions={filterOptions}
            filterType={
              filterType as {
                down_payment: SearchFiltersTypes.DOWN_PAYMENT;
                monthly_installments: SearchFiltersTypes.MONTHLY_INSTALLMENTS;
                installment_years: SearchFiltersTypes.INSTALLMENT_YEARS;
              }
            }
          />
        );
      case FilterStyles.DELIVERY:
        return (
          <DeliveryFilter
            filterOptions={filterOptions}
            filterType={
              filterType as {
                delivery_date: SearchFiltersTypes.DELIVERY_DATE;
                delivery_years: SearchFiltersTypes.DELIVERY_YEARS;
              }
            }
          />
        );
      default:
        return null;
    }
  };
  return filterStyle ? (
    <Container
      id={filterName}
      className={notSearchFilters ? 'old-filter' : 'filter-wrapper'}
    >
      <div className="filter-header">
        <div className="filter-title">{t(filterName)}</div>
        {withReset && (
          <div className="filter-reset">
            <button
              className="reset-btn"
              onClick={() =>
                isEqual(filterType, {
                  delivery_date: SearchFiltersTypes.DELIVERY_DATE,
                  delivery_years: SearchFiltersTypes.DELIVERY_YEARS
                }) && searchFiltersState.searchFilters.nawy_now
                  ? () => {}
                  : resetFilter(filterType, searchFiltersDispatch, router)
              }
            >
              {t('reset')}
            </button>
          </div>
        )}
      </div>

      <div className="filter-body">{getFilterComponent()}</div>
    </Container>
  ) : null;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .option-button {
    padding: 8px;
    border: 1px solid #e0e0e0;
    border-radius: 360px;
    background-color: #fff;
    color: #5b6573;
  }

  .add-btn {
    display: flex;
    width: 100%;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 360px;
    border: 1px solid #c6d0de;
    background: #fff;

    span {
      color: #1a2028;
      text-align: center;
      font-size: 14px;
      font-family: materialMedium;
    }
  }
  .filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filter-title {
      text-transform: capitalize;
      font-size: 14px;
      color: #1a2028;
      line-height: 150%;
      font-family: materialMedium;

      @media screen and (max-width: ${props =>
          props.theme.breakPoints.mobile.max}) {
        font-size: 17px;
        font-family: materialBold;
      }
    }

    .reset-btn {
      background: none;
      border: none;
      outline: none;
      color: #1e6dac;
      text-decoration: underline;
      font-size: 12px;
      cursor: pointer;
      font-family: materialRegular;
    }
  }

  &.old-filter {
    width: 100%;
    min-height: 57px;
    background-color: ${props => props.theme.light.colors.lightGrey};
    border-radius: 8px;
    margin-bottom: 16px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-out;
    .filter-title {
      margin-bottom: 8px;
      font-size: ${props => props.theme.font.sizes.l};
      font-family: materialBold;
      color: ${props => props.theme.light.colors.black};
    }

    .chip-btn {
      background: ${props => props.theme.light.colors.white};
      color: ${props => props.theme.light.colors.darkGrey};
      border: 1px solid ${props => props.theme.light.colors.white};
      font-size: ${props => props.theme.font.sizes.xxs};
      border-radius: 8px;
      padding: 5px 8px;
      &:hover {
        color: ${props => props.theme.light.colors.secondary};
        border: 1px solid ${props => props.theme.light.colors.secondary};
      }
    }
  }
`;

export default FilterWrapper;
