import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import useMaintainCompareState from '../features/compare/hooks/MaintainCompareState';
import useMaintainFavoritesState from '../features/favorite/hooks/MaintainFavorites';
import {
  configureGA4,
  configureMixpanel,
  getTrackerClientId,
  identifyMoengage,
  identifyTiktokUser,
  initConsent,
  logMixpanelPageView
} from '../new-lib/analytics';
import { trackActivity } from '../new-lib/apis';
import {
  setEvent,
  setReferer,
  setUser,
  setUserLocation,
  setWebview,
  WEBVIEW
} from '../redux/actions/appActions';
import { generateRandomId, getRequest } from '../helpers/common';
import { getElementsToHide, removeElements } from '../new-lib/webview';
import {
  browserName,
  deviceDetect,
  deviceType,
  mobileModel,
  mobileVendor,
  osName
} from 'react-device-detect';
import { getUserFavorites } from '../helpers/favorites';
import { addAllWatchList } from '../redux/slices/favoriteSlice';
import sbjs from 'sourcebuster';
import { ConfigsApis } from '../new-lib/apis/configs.apis';

export const useInitialization = () => {
  const { asPath, query, locale } = useRouter();
  const webview = useSelector((state: any) => state.app.webview);
  const userLocation = useSelector((state: any) => state.app.userLocation);
  const isMounted = useRef(false);

  const cookies = new Cookies();
  const dispatch = useDispatch<any>();

  useMaintainCompareState();
  useMaintainFavoritesState();

  // Track user's activity on mount
  useEffect(() => {
    const submitTrackingView = async () => {
      const clientId = await getTrackerClientId();
      cookies.set('clientID', clientId);

      try {
        const res = await trackActivity(
          {
            client_id: clientId,
            client_data: {
              ...deviceDetect(window.navigator.userAgent)
            },
            sbjs: {
              ...sbjs.get,
              current: {
                ...sbjs.get.current,
                cmp: query.utm_campaign || sbjs.get.current.cmp,
                src: query.utm_source || sbjs.get.current.src,
                typ:
                  query.utm_campaign || query.utm_source
                    ? 'utm'
                    : sbjs.get.current.typ
              }
            },
            request: getRequest(query, asPath)
          },
          locale
        );
        dispatch(setUser(res.user_id));
        dispatch(setEvent(res.event_id));
        localStorage.setItem('user_id', res.user_id);
        localStorage.setItem('event_id', res.event_id);

        identifyMoengage(res.user_id, locale ? locale : 'en');
        identifyTiktokUser(res.user_id);

        // Configure mixpanel after userId is available
        configureMixpanel(res.user_id, getAnalyticsPayload(res.user_id));
      } catch (error) {
        localStorage.setItem('random_event_id', generateRandomId());
      }
    };

    if (!userLocation) {
      ConfigsApis.checkIfEuCountry()
        .then(isEuCountry => {
          dispatch(setUserLocation({ isEuCountry }));
          return initConsent(isEuCountry);
        })
        .finally(() => {
          configureGA4(getAnalyticsPayload());
          return submitTrackingView();
        });
    }
  }, []);

  // Init sourcebuster and get user's favorites
  useEffect(() => {
    sbjs.init();
  }, []);

  useEffect(() => {
    dispatch(setReferer(cookies.get('rf')));
  }, [asPath]);

  useEffect(() => {
    if (cookies.get('clientID')) {
      getUserFavorites().then(favorites => {
        dispatch(addAllWatchList(favorites));
      });
    }
  }, [cookies.get('clientID')]);

  // Get GA4 | Mixpanel payload
  const getAnalyticsPayload = (userId?: string) => {
    const deviceDetails = {
      brand: mobileVendor,
      model: mobileModel,
      browserName: browserName,
      deviceType: deviceType
    };
    return {
      locale: locale,
      utm_source: sbjs.get.current.src,
      utm_medium: sbjs.get.current.mdm,
      utm_campaign: sbjs.get.current.cmp,
      user_tracking_id: userId ?? '',
      device_details: JSON.stringify(deviceDetails),
      OS: osName,
      platform: 'web'
    };
  };

  // Configure layout for 3rd party iFrame or WebView clients
  useEffect(() => {
    if (webview) {
      removeElements(getElementsToHide(webview));
    } else {
      if (query.partners) {
        dispatch(setWebview(WEBVIEW.PARTNERS));
      }
      if (query['hide-all']) {
        dispatch(setWebview(WEBVIEW.APP));
      }
    }
  }, [asPath]);

  // Track user's activity on route change
  useEffect(() => {
    const submitTrackingView = async () => {
      const clientId = await getTrackerClientId();

      try {
        const res = await trackActivity({
          client_id: clientId,
          request: getRequest(query, asPath)
        });

        dispatch(setUser(res.user_id));
        dispatch(setEvent(res.event_id));
        localStorage.setItem('event_id', res.event_id);
        localStorage.setItem('user_id', res.user_id);
      } catch (error) {
        localStorage.setItem('random_event_id', generateRandomId());
      }
    };

    if (isMounted.current) {
      logMixpanelPageView();
      submitTrackingView();
    } else {
      isMounted.current = true;
    }
  }, [asPath]);
  // ---------------------
};
