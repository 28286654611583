import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';
import ButtonIcon from '../../ui/ButtonIcon';
import CloseIcon from '../../svg-icons/CloseIcon';
import Nawy from '../../../public/assets/icons/common/nawy.svg';
import Home from '../../../public/assets/icons/common/home.svg';
import Lens from '../../../public/assets/icons/common/lens.svg';
import Blog from '../../../public/assets/icons/common/blog.svg';
import About from '../../../public/assets/icons/common/about.svg';
import Contact from '../../../public/assets/icons/common/contact.svg';
import Launches from '../../../public/assets/icons/common/launches.svg';
import NawyNow from '../../../public/assets/icons/common/nawy-now.svg';
import Sell from '../../../public/assets/icons/common/sell.svg';
import JoinUs from '../../../public/assets/icons/common/join-us.svg';
import Favorites from '../../../public/assets/icons/common/favorites.svg';
import HowItWorks from '../../../public/assets/icons/common/howitworks.svg';
import Terms from '../../../public/assets/icons/common/terms.svg';
import Privacy from '../../../public/assets/icons/common/privacy.svg';
import Verify from '../../../public/assets/icons/common/verify.svg';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Dispatch, SetStateAction } from 'react';
import ImageWrapper from '../../ImageWrapper';
import IconHolder from '../../ui/IconHolder';
import { HeaderLanguage } from './HeaderLanguage';

interface Props {
  handleNavbarItemClick: (eventName: string, eventFeature: string) => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const HeaderMenu = ({ handleNavbarItemClick, setIsOpen }: Props) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const { locale } = useRouter();
  const handleLinkClick = (e: Event, href: string) => {
    if (router.asPath.includes(href)) {
      e.preventDefault();
    }
  };
  return (
    <MenuContainer data-test="sidebar">
      <ContainerHeader>
        <Link href="/" prefetch legacyBehavior>
          <ImageWrapper
            src={Nawy}
            alt="Nawy"
            width={100.5}
            height={25.6}
            onClick={() => {
              setIsOpen(false);
              handleNavbarItemClick('nawy_logo_click', 'other');
            }}
          />
        </Link>
        <ButtonIcon
          backgroundColor="#F3F3F3"
          backgroundHover="none"
          border="none"
          color="#646464"
          hoverColor="none"
          icon={<CloseIcon />}
          onClick={() => {
            setIsOpen(false);
            handleNavbarItemClick('navbar_close_click', 'other');
          }}
          btnClass="close-btn"
        />
      </ContainerHeader>
      <ContainerBody>
        <ul>
          <Link href="/" prefetch legacyBehavior>
            <li
              onClick={() => {
                setIsOpen(false);
                handleNavbarItemClick('home_click', 'other');
              }}
            >
              <IconHolder>
                <ImageWrapper src={Home} alt="Home" width={29} height={29} />
              </IconHolder>
              <span> {t('Home')} </span>
            </li>
          </Link>
          <Link href="/search" prefetch legacyBehavior>
            <li
              onClick={(event: any) => {
                handleLinkClick(event, '/search');
                setIsOpen(false);
                handleNavbarItemClick('search_click', 'other');
              }}
            >
              <IconHolder>
                <ImageWrapper
                  src={Lens}
                  alt="Lens"
                  width={22.5}
                  height={22.5}
                />
              </IconHolder>
              <span> {t('Search')} </span>
            </li>
          </Link>
          <Link href="/nawy-now" prefetch legacyBehavior>
            <li
              onClick={() => {
                setIsOpen(false);
                handleNavbarItemClick('nawynow_click', 'other');
              }}
            >
              <IconHolder>
                <ImageWrapper
                  src={NawyNow}
                  alt="Nawy Now"
                  width={40}
                  height={40}
                />
              </IconHolder>
              <span> Nawy Now </span>
            </li>
          </Link>
          <Link href="/new-launches" prefetch legacyBehavior>
            <li
              onClick={() => {
                setIsOpen(false);
                handleNavbarItemClick('launches_click', 'other');
              }}
            >
              <IconHolder>
                <ImageWrapper
                  src={Launches}
                  alt="Launches"
                  width={40}
                  height={40}
                />
              </IconHolder>
              <span> {t('launches')} </span>
            </li>
          </Link>
          <Link href="/sell-my-property" prefetch legacyBehavior>
            <li
              onClick={() => {
                setIsOpen(false);
                handleNavbarItemClick('sell_click', 'other');
              }}
            >
              <IconHolder>
                <ImageWrapper src={Sell} alt="Sell" width={29} height={28.6} />
              </IconHolder>
              <span> {t('sell')} </span>
            </li>
          </Link>
          <Link href="/contact" prefetch={false} legacyBehavior>
            <li
              onClick={() => {
                setIsOpen(false);
                handleNavbarItemClick('contact_us_click', 'other');
              }}
            >
              <IconHolder>
                <ImageWrapper
                  src={Contact}
                  alt="Contact"
                  width={29}
                  height={29}
                />
              </IconHolder>
              <span> {t('Contact')} </span>
            </li>
          </Link>
          <Link href="/favorites" prefetch legacyBehavior>
            <li
              onClick={() => {
                setIsOpen(false);
                handleNavbarItemClick('favorite_click', 'other');
              }}
              className="fav-icon"
            >
              <IconHolder>
                <ImageWrapper
                  src={Favorites}
                  alt="Favorites"
                  width={31}
                  height={28}
                />
              </IconHolder>
              <span> {t('favorites')} </span>
            </li>
          </Link>
          <li
            onClick={() => {
              handleNavbarItemClick('careers_click', 'other');
              router.push('https://apply.workable.com/nawy-real-estate/');
            }}
          >
            <IconHolder>
              <ImageWrapper
                src={JoinUs}
                alt="Careers @ Nawy"
                width={40}
                height={40}
              />
            </IconHolder>
            <span>{t('careers')}</span>
          </li>
          <li
            onClick={() => {
              handleNavbarItemClick('blog_click', 'other');
              window.location.href = `https://www.nawy.com/blog${
                locale === 'ar' ? '/ar' : ''
              }`;
            }}
          >
            <IconHolder>
              <ImageWrapper src={Blog} alt="Blog" width={29} height={29} />
            </IconHolder>
            <span> {t('Blog')} </span>
          </li>
          <Link href="/about-us" prefetch legacyBehavior>
            <li
              onClick={() => {
                setIsOpen(false);
                handleNavbarItemClick('about_us_click', 'other');
              }}
            >
              <IconHolder>
                <Image src={About} alt="About" width={10} height={25} />
              </IconHolder>
              <span> {t('About')} </span>
            </li>
          </Link>
          <Link href="/how-it-works" prefetch={false} legacyBehavior>
            <li
              onClick={() => {
                setIsOpen(false);
                handleNavbarItemClick('how_it_works_click', 'other');
              }}
            >
              <IconHolder>
                <ImageWrapper
                  src={HowItWorks}
                  alt="How It Works"
                  width={40}
                  height={40}
                />
              </IconHolder>
              <span> {t('how_it_works')} </span>
            </li>
          </Link>
          <Link href="/terms" prefetch={false} legacyBehavior>
            <li
              onClick={() => {
                setIsOpen(false);
                handleNavbarItemClick('terms_conditions_click', 'other');
              }}
            >
              <IconHolder>
                <ImageWrapper src={Terms} alt="Terms" width={40} height={40} />
              </IconHolder>
              <span> {t('terms_of_use')} </span>
            </li>
          </Link>

          <Link href="/privacy-policy" prefetch={false} legacyBehavior>
            <li
              onClick={() => {
                setIsOpen(false);
                handleNavbarItemClick('privacy_policy_click', 'other');
              }}
            >
              <IconHolder>
                <ImageWrapper
                  src={Privacy}
                  alt="Privacy"
                  width={30}
                  height={40}
                />
              </IconHolder>
              <span> {t('privacy_policy')} </span>
            </li>
          </Link>
          <Link href="/verify-agent" prefetch legacyBehavior>
            <li
              onClick={() => {
                setIsOpen(false);
                handleNavbarItemClick('verify_agent', 'other');
              }}
            >
              <IconHolder>
                <ImageWrapper
                  src={Verify}
                  alt="Verify"
                  width={40}
                  height={38}
                />
              </IconHolder>
              <span>{t('verify_agent', { ns: 'common' })}</span>
              <span className="new-sign">{t('new')}</span>
            </li>
          </Link>

          <HeaderLanguage showIcon={true} setIsOpen={setIsOpen} />
          <li className="footer">
            <p>{t('hotline_header')}</p>
            <a href="tel:15812">15812</a>
          </li>
        </ul>
      </ContainerBody>
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  @media (pointer: none), (pointer: coarse) {
    width: 100svw;
    height: 100dvh;
    padding: 20px;
  }

  width: 100%;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const ContainerHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  .close-btn {
    border-radius: 0px;
  }
`;

const ContainerBody = styled.div`
  ul {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    li,
    .language-icon {
      font-size: ${props => props.theme.font.sizes.s};
      font-family: materialRegular;
      display: flex;
      align-items: center;
      padding: 8px 16px;
      gap: 8px;
      width: 100%;
      height: 60px;
      background: #f8f8f8;
      border: 1px solid #e8e8e8;
      border-radius: ${props => props.theme.borderRadius};
      white-space: nowrap;
      color: ${props => props.theme.light.colors.black};

      .new-sign {
        margin-inline-start: auto;
        background: ${props => props.theme.light.colors.primaryOrange};
        border-radius: 4px;
        padding: 3px 6px;
        font-family: materialBold;
        color: ${props => props.theme.light.colors.white};
        font-size: 8px;
      }

      &.fav-icon,
      &.language-icon {
        svg {
          width: 35px;
          height: auto;
        }
      }

      &.footer {
        height: 99px;
        margin-top: 8px;
        background: #f8f8f8;
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          font-family: materialRegular;
          font-size: ${props => props.theme.font.sizes.s};
          color: ${props => props.theme.light.colors.darkGrey};
        }
        a {
          font-family: materialBold;
          font-size: 40px;
          color: ${props => props.theme.light.colors.primaryBlue};
        }
      }

      @media screen and (max-width: ${props =>
          props.theme.breakPoints.desktop.max}) {
        cursor: pointer;
      }
    }
  }
`;

export default HeaderMenu;
