import { Residence } from 'schema-dts';
import { JsonLd } from './JsonLD';
import { SeoUtils } from '../../../new-lib/utilities/seo-utils';
import { Availability } from '../../../new-lib/types/entity';

function ResidenceSchema(props: { pageProps: any; locale?: string }) {
  const compound = props.pageProps?.compound;
  if (!compound) return <></>;
  const address: {
    addressLocality?: string;
    addressRegion?: string;
    addressCountry?: string;
    streetAddress?: string;
  } = {
    addressCountry: 'EG'
  };
  if (compound.area?.parent_area) {
    address.streetAddress = `${compound.name}, ${compound.area?.name}, ${compound.area?.parent_area?.name}`;
    address.addressLocality = compound.area?.name;
    address.addressRegion = compound.area?.parent_area?.name;
  } else {
    address.streetAddress = `${compound.name}, ${compound.area?.name}`;
    address.addressRegion = compound.area?.name;
  }

  const isArabic = props.locale === 'ar';

  const slug = isArabic ? compound.all_slugs?.ar : compound.all_slugs?.en;
  const name = isArabic ? `وحدات ${compound.name}` : `${compound.name} Units`;
  const description = isArabic
    ? `وحدات للبيع في ${compound.display_name ?? compound.name}`
    : `Units for sale in ${compound.display_name ?? compound.name}`;

  const residence: Residence = {
    '@type': 'Residence',
    name: SeoUtils.getCompoundMetaTitle(compound, props.locale),
    image: compound.cover_image_path,
    description: SeoUtils.getCompoundMetaDescription(compound, props.locale),
    address: {
      '@type': 'PostalAddress',
      ...address
    },
    potentialAction: {
      '@type': 'BuyAction',
      object: {
        '@type': 'Product',
        name: name,
        image:
          compound.compound_images?.[0]?.image_path ??
          compound.cover_image_path,
        description: description,
        offers: {
          '@type': 'Offer',
          price:
            compound.primary_min_price && compound.primary_min_price > 0
              ? compound.primary_min_price
              : compound.resale_min_price,
          priceCurrency: compound.currency == 'جم' ? 'EGP' : compound.currency,
          availability:
            compound.properties_count > 0
              ? Availability.InStock
              : Availability.OutOfStock
        }
      }
    },
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${process.env.NEXT_PUBLIC_HOST}/compound/${slug}`
    }
  };

  return JsonLd<Residence>('residence-schema', {
    '@context': 'https://schema.org',
    ...residence
  });
}

export default ResidenceSchema;
