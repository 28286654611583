import RangeFilterPresentation from './RangeFilterPresentation';
import { SearchFiltersTypes } from '../../../../../reducers/search-filters/reducer';
import { useEffect, useState } from 'react';
import {
  SearchContext,
  SearchContextInterface
} from '../../../context/SearchContext';
import {
  FilterInputTypes,
  SearchFiltersActionTypes
} from '../../../../../reducers/search-filters/actions';
import React from 'react';
import { logEvent } from '../../../../../new-lib/analytics';
import { filterEventMap } from '../../../../../helpers/search-filters';
import { updateQuery } from '../../../../../helpers/common';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { SearchViewTypes } from '../../../../../new-lib/constants/common';

interface ContainerProps {
  filterOptions: any;
  filterType: { min: SearchFiltersTypes; max: SearchFiltersTypes };
  prefix?: string;
  step?: number;
}

const RangeFilterContainer = ({
  filterOptions,
  filterType,
  prefix,
  step
}: ContainerProps) => {
  const { searchFiltersDispatch, searchFiltersState } = React.useContext(
    SearchContext
  ) as SearchContextInterface;
  const router = useRouter();
  const [range, setRange] = useState([filterOptions.min, filterOptions.max]);
  const handleRangeChange = (values: number[]) => {
    setRange(values);
  };
  const searchView = useSelector((state: any) => state.map.searchView);

  const applyFilter = () => {
    searchFiltersDispatch({
      type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
      payload: {
        name: filterType.min as FilterInputTypes,
        value: range[0]
      }
    });
    searchFiltersDispatch({
      type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
      payload: {
        name: filterType.max as FilterInputTypes,
        value: range[1]
      }
    });
    logEvent.search(
      `filter_${filterEventMap(filterType.min)}`,
      'filtersMenu',
      'filter',
      searchFiltersState,
      { [filterType.min]: range[0], [filterType.max]: range[1] }
    );
    if (router.query?.view !== SearchViewTypes.MAP) {
      updateQuery(
        router,
        [
          {
            name: filterType.min,
            value: range[0]
          },
          {
            name: filterType.max,
            value: range[1]
          }
        ],
        undefined
      );
    }
  };

  useEffect(() => {
    setRange([
      searchFiltersState.searchFilters[filterType.min] || filterOptions.min,
      searchFiltersState.searchFilters[filterType.max] || filterOptions.max
    ]);
  }, [
    searchFiltersState.searchFilters[filterType.max],
    searchFiltersState.searchFilters[filterType.min]
  ]);

  const handleMinRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value.replace(/,/g, ''));
    if (newValue > filterOptions.max) {
      return;
    }
    setRange([newValue, range[1]]);
  };

  const handleMaxRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value.replace(/,/g, ''));
    if (newValue > filterOptions.max) {
      return;
    }
    setRange([range[0], newValue]);
  };

  return (
    <RangeFilterPresentation
      filterOptions={filterOptions}
      range={range}
      handleRangeChange={handleRangeChange}
      handleMinRangeChange={handleMinRangeChange}
      handleMaxRangeChange={handleMaxRangeChange}
      prefix={prefix}
      step={step}
      applyFilter={applyFilter}
    />
  );
};

export default RangeFilterContainer;
