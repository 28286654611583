import { useRouter } from 'next/router';
import { SearchFiltersActionTypes } from '../../../../../reducers/search-filters/actions';
import { SearchFiltersTypes } from '../../../../../reducers/search-filters/reducer';
import {
  convertStringArrayToNumberArray,
  updateQuery
} from '../../../../../helpers/common';
import { InputOption } from '../../../../../new-lib/types/search-filters';
import { useCallback, useContext } from 'react';
import CheckboxPresentation from './CheckboxPresentation';
import { logEvent } from '../../../../../new-lib/analytics';
import { getNowDate } from '../../../../../helpers/search-options/common';
import {
  SearchContext,
  SearchContextInterface
} from '../../../../search-new/context/SearchContext';
import { SearchViewTypes } from '../../../../../new-lib/constants/common';

export type CheckBoxFilterTypes =
  | SearchFiltersTypes.AMENITIES
  | SearchFiltersTypes.NAWY_NOW;

interface ContainerProps {
  options: InputOption[];
}

const CheckBoxContainer = ({ options }: ContainerProps): JSX.Element => {
  const { searchFiltersState, searchFiltersDispatch } = useContext(
    SearchContext
  ) as SearchContextInterface;
  const router = useRouter();

  useCallback(() => {}, [searchFiltersState.searchFilters.amenities]);

  const handleChange = (filterType: CheckBoxFilterTypes, options: any) => {
    let newValues: boolean | number[] | undefined = undefined;
    if (filterType === SearchFiltersTypes.AMENITIES) {
      const filtered = searchFiltersState.searchFilters[filterType].filter(
        item => item !== options.value
      );
      const shouldBeRemoved =
        filtered.length !==
        searchFiltersState.searchFilters[SearchFiltersTypes.AMENITIES].length;
      newValues = shouldBeRemoved ? filtered : [...filtered, options.value];
      searchFiltersDispatch({
        type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
        payload: { name: SearchFiltersTypes.AMENITIES, value: newValues }
      });
    } else if (filterType === SearchFiltersTypes.NAWY_NOW) {
      const checked = !searchFiltersState.searchFilters[filterType];
      newValues = checked;
      searchFiltersDispatch({
        type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
        payload: { name: filterType, value: checked }
      });
      if (checked) {
        searchFiltersDispatch({
          type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
          payload: {
            name: SearchFiltersTypes.DELIVERY_DATE,
            value: getNowDate()
          }
        });
        searchFiltersDispatch({
          type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
          payload: {
            name: SearchFiltersTypes.DELIVERY_YEARS,
            value: []
          }
        });
      } else {
        searchFiltersDispatch({
          type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
          payload: {
            name: SearchFiltersTypes.DELIVERY_DATE,
            value: undefined
          }
        });
      }
    }

    if (newValues) {
      logEvent.search(
        `filter_${filterType}`,
        'filtersMenu',
        'filter',
        searchFiltersState,
        { [filterType]: newValues }
      );
    }

    if (router.query?.view !== SearchViewTypes.MAP) {
      updateQuery(
        router,
        [
          ...(newValues && (typeof newValues === 'boolean' || newValues.length)
            ? [{ name: filterType, value: newValues.toString() }]
            : []),
          ...(!router.asPath.includes('/nawy-now')
            ? [{ name: 'page_number', value: 1 }]
            : [])
        ],
        [
          ...(newValues && (typeof newValues === 'boolean' || newValues.length)
            ? []
            : [filterType])
        ]
      );
    }
  };

  return <CheckboxPresentation options={options} handleChange={handleChange} />;
};

export default CheckBoxContainer;
