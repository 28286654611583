const ProfileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M19.9985 26.0001C25.5214 26.0001 29.9985 21.523 29.9985 16.0001C29.9985 10.4773 25.5214 6.00012 19.9985 6.00012C14.4757 6.00012 9.99854 10.4773 9.99854 16.0001C9.99854 21.523 14.4757 26.0001 19.9985 26.0001Z"
        stroke="#015C9A"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.99854 41.9996C3.99877 39.4816 4.59332 36.9991 5.73385 34.7541C6.87437 32.5092 8.52867 30.5651 10.5622 29.08C12.5957 27.5949 14.951 26.6107 17.4366 26.2075C19.9222 25.8043 22.4678 25.9935 24.8665 26.7596"
        stroke="#015C9A"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.9987 42.0001C39.3124 42.0001 41.9987 39.3138 41.9987 36.0001C41.9987 32.6864 39.3124 30.0001 35.9987 30.0001C32.6849 30.0001 29.9987 32.6864 29.9987 36.0001C29.9987 39.3138 32.6849 42.0001 35.9987 42.0001Z"
        stroke="#015C9A"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.9984 44.0001L40.1984 40.2001"
        stroke="#015C9A"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ProfileIcon;
