import { SearchFiltersTypes } from './reducer';

export enum SearchFiltersActionTypes {
  RESET = 'RESET',
  UPDATE_FILTERS_WITH_SINGLE_VALUE = 'UPDATE_FILTERS_WITH_SINGLE_VALUE',
  UPDATE_FILTERS_WITH_MULTISELECT = 'UPDATE_FILTERS_WITH_MULTISELECT',
  UPDATE_FILTERS_WITH_MIN_MAX = 'UPDATE_FILTERS_WITH_MIN_MAX',
  UPDATE_SORTING_ORDER = 'UPDATE_SORTING_ORDER',
  UPDATE_PAGE_NUMBER = 'UPDATE_PAGE_NUMBER',
  SET_INITIAL_FILTERS = 'SET_INITIAL_FILTERS'
}
export type FilterMultiSelectTypes =
  | SearchFiltersTypes.COMPOUNDS
  | SearchFiltersTypes.AREAS
  | SearchFiltersTypes.DEVELOPERS
  | SearchFiltersTypes.PROPERTY_TYPES
  | SearchFiltersTypes.INSTALLMENT_YEARS
  | SearchFiltersTypes.AMENITIES
  | SearchFiltersTypes.BEDROOMS
  | SearchFiltersTypes.BATHROOMS
  | SearchFiltersTypes.COMPLETION_TYPE
  | SearchFiltersTypes.SALE_TYPE
  | SearchFiltersTypes.DELIVERY_YEARS;

export type FilterInputTypes =
  | SearchFiltersTypes.MIN_PRICE
  | SearchFiltersTypes.MAX_PRICE
  | SearchFiltersTypes.MIN_UNIT_AREA
  | SearchFiltersTypes.MAX_UNIT_AREA
  | SearchFiltersTypes.DOWN_PAYMENT
  | SearchFiltersTypes.MONTHLY_INSTALLMENTS;

type UpdateFiltersWithSingleValueAction = {
  type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE;
  payload: {
    name:
      | SearchFiltersTypes.CATEGORY
      | SearchFiltersTypes.DOWN_PAYMENT
      | SearchFiltersTypes.MONTHLY_INSTALLMENTS
      | SearchFiltersTypes.MIN_PRICE
      | SearchFiltersTypes.MAX_PRICE
      | SearchFiltersTypes.MIN_UNIT_AREA
      | SearchFiltersTypes.MAX_UNIT_AREA
      | SearchFiltersTypes.HAS_GARDEN
      | SearchFiltersTypes.HAS_ROOF
      | SearchFiltersTypes.DELIVERY_DATE
      | SearchFiltersTypes.NAWY_NOW;

    value?: number | string | boolean;
  };
};

type UpdateFiltersWithMultiSelectAction = {
  type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT;
  payload: {
    name: FilterMultiSelectTypes;
    value: number[] | string[];
  };
};

type UpdatePageNumberAction = {
  type: SearchFiltersActionTypes.UPDATE_PAGE_NUMBER;
  payload: {
    name: 'page_number';
    value: number;
  };
};

type UpdateSortingOrderAction = {
  type: SearchFiltersActionTypes.UPDATE_SORTING_ORDER;
  payload: {
    order_by: string;
    order_direction: string;
  };
};
type ResetFiltersAction = {
  type: SearchFiltersActionTypes.RESET;
};

type SetInitialFilters = {
  type: SearchFiltersActionTypes.SET_INITIAL_FILTERS;
  payload: {
    initial_filters: { filter: SearchFiltersTypes; value: any }[];
  };
};

export type SearchFiltersActions =
  | ResetFiltersAction
  | UpdateFiltersWithSingleValueAction
  | UpdateFiltersWithMultiSelectAction
  | UpdateSortingOrderAction
  | UpdatePageNumberAction
  | SetInitialFilters;
