import ImageWrapper from '../ImageWrapper';

const TwitterIcon = () => {
  return (
    <ImageWrapper
      src={`/assets/icons/new/twitter-icon.svg`}
      alt={'twitter-icon'}
      width={30}
      height={30}
    />
  );
};
export default TwitterIcon;
