import { Pagination } from './api.types';
import { newApi } from './api.instance';
import { DropdownOptionDto } from '../../features/search-new/dtos/dropdown-option.dto';
import { DeveloperLaunchDto } from '../dtos/developers/developer-launch.dto';

export class DevelopersApis {
  public static list = async (pagination: Pagination, language?: string) => {
    return newApi({ ssrLocale: language })
      .get(
        `/v1/developers?page=${pagination.page}&pageSize=${pagination.pageSize}`
      )
      .then(({ data }) => data);
  };

  public static listAll = async (
    language?: string
  ): Promise<DropdownOptionDto[]> => {
    return newApi({ ssrLocale: language })
      .get(`/v1/developers/all`)
      .then(({ data }) => {
        return (
          data
            ?.map((developer: any) => ({
              value: developer.id,
              options: { displayedValue: developer.name }
            }))
            .filter(
              (developer: any) =>
                developer.value && developer.options.displayedValue
            ) ?? []
        );
      });
  };

  public static getDeveloperLaunches = async (
    id: number,
    language?: string
  ): Promise<DeveloperLaunchDto[]> => {
    return newApi({ ssrLocale: language })
      .get(`/v1/developers/${id}/launches`)
      .then(({ data }) => data);
  };
}
