import { newApi, oldApi } from './api.instance';
import { objToQuery } from '../../helpers/common';

export class FavoritesApis {
  private static END_POINT = `v1/p/me/favorites`;

  public static getFavoritesIds = async (): Promise<
    { id: number; type: 'Property' | 'Compound' }[]
  > => {
    return newApi()
      .get(this.END_POINT)
      .then(({ data }) => data);
  };

  public static getFavorites = async (clientID: string, language?: string) => {
    const query = { client_id: clientID, ...(language && { language }) };
    return oldApi()
      .get(`/watchlists?${objToQuery(query)}`)
      .then(({ data }) => data);
  };

  public static updateFavorites = async (
    id: number,
    type: 'Property' | 'Compound',
    action: 'add' | 'remove'
  ): Promise<void> => {
    await newApi()
      .post(this.END_POINT, { id, type, action })
      .catch(error => {});
  };
}
