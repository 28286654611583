import ImageWrapper from '../ImageWrapper';

const FacebookIcon = () => {
  return (
    <ImageWrapper
      src={`/assets/icons/new/facebook-icon.svg`}
      alt={'facebook-icon'}
      width={30}
      height={30}
    />
  );
};
export default FacebookIcon;
