import dynamic from 'next/dynamic';
import Breadcrumbs from '../../../new-components/Breadcrumbs';
import { useContext, useEffect } from 'react';
import {
  SearchContext,
  SearchContextInterface
} from '../../search-new/context/SearchContext';
import { SearchFiltersActionTypes } from '../../../reducers/search-filters/actions';
import { SearchFiltersTypes } from '../../../reducers/search-filters/reducer';
import { useRouter } from 'next/router';
import { EntityOffer } from '../../../new-lib/types/entity';
import { useSelector } from 'react-redux';
const AreaView = dynamic(() => import('./AreaView'));
const MobileAreaView = dynamic(() => import('./MobileAreaView'));

interface AreaProps {
  area: any;
  offers: EntityOffer[];
  launches: any;
  filterOptions: any;
}

export const getAreaBreadcrumbsItems = (area: any, locale: string) => {
  const breadcrumbItems = [
    {
      displayedName: area.name,
      path: `/area/${area.all_slugs[locale]}`
    }
  ];
  return breadcrumbItems;
};
const getAreaBreadcrumbs = (area: any, locale: string) => {
  return <Breadcrumbs itemsToShow={getAreaBreadcrumbsItems(area, locale)} />;
};
const AreaContainer = ({
  area,
  offers,
  launches,
  filterOptions
}: AreaProps) => {
  const isMobile = useSelector((state: any) => state.app.isMobile);

  const { asPath, locale } = useRouter();
  const { searchFiltersDispatch } = useContext(
    SearchContext
  ) as SearchContextInterface;

  useEffect(() => {
    searchFiltersDispatch({
      type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
      payload: {
        name: SearchFiltersTypes.AREAS,
        value: [area.id]
      }
    });
  }, [asPath]);

  return isMobile ? (
    <MobileAreaView
      area={area}
      offers={offers}
      launches={launches}
      filterOptions={filterOptions}
      breadcrumbs={getAreaBreadcrumbs(area, locale as string)}
    />
  ) : (
    <AreaView
      area={area}
      offers={offers}
      launches={launches}
      filterOptions={filterOptions}
      breadcrumbs={getAreaBreadcrumbs(area, locale as string)}
    />
  );
};
export default AreaContainer;
