import { Dispatch } from 'react';
import { NextRouter } from 'next/router';
import { InputOption } from '../new-lib/types/search-filters';
import {
  SearchFiltersActionTypes,
  SearchFiltersActions
} from '../reducers/search-filters/actions';
import {
  BaseSearchFiltersType,
  SearchFiltersInterface,
  SearchFiltersTypes
} from '../reducers/search-filters/reducer';
import {
  convertStringArrayToNumberArray,
  removeNullUndefinedEmptyFromObject,
  updateQuery
} from './common';
import { isValidDate } from './search-options/common';
import { fetchSearchResults } from '../new-lib/apis';
import { isEqual } from './isEqual';
import { AreasApis } from '../new-lib/apis/areas.apis';
import { MapSearchFiltersTypes } from '../features/map/types';

export class FiltersUtils {
  private static NEW_FILTERS_KEYS: any = {
    compounds: 'compoundsIds',
    developers: 'developerIds',
    property_types: 'propertyTypeIds',
    amenities: 'propertyAmenityIds',
    bedrooms: 'numberOfBedrooms',
    bathrooms: 'numberOfBathrooms',
    finishing: 'finishing',
    min_price: 'minPrice',
    max_price: 'maxPrice',
    min_unit_area: 'minUnitArea',
    max_unit_area: 'maxUnitArea',
    delivery_date: 'minReadyBy',
    delivery_years: 'readyBy',
    down_payment: 'maxDownPayment',
    monthly_installments: 'maxInstallments',
    installment_years: 'installmentYears',
    sale_type: 'resaleType',
    nawy_now: 'isNawyNow'
  };

  public static mapFiltersToNew = (searchFilters: BaseSearchFiltersType) => {
    return Object.keys(searchFilters).reduce((acc: any, key) => {
      const mappedKey = FiltersUtils.NEW_FILTERS_KEYS[key];
      const getValue = () => {
        if (mappedKey === MapSearchFiltersTypes.minReadyBy) {
          const oldVal = searchFilters[key as keyof typeof searchFilters];
          if (oldVal) {
            if (typeof oldVal === 'string') {
              return new Date(oldVal).getFullYear();
            }
            return undefined;
          }
        } else {
          return searchFilters[key as keyof typeof searchFilters];
        }
      };
      const value: any = getValue();
      if (
        mappedKey &&
        value &&
        (Array.isArray(value) ? value.length > 0 : true)
      ) {
        acc[mappedKey] = value;
      }
      return acc;
    }, {});
  };
}

export const initializeSearchFiltersFromQuery = (
  query: object,
  dispatch: React.Dispatch<SearchFiltersActions>
) => {
  if (query && Object.keys(query).length == 0) return;
  for (const [key, value] of Object.entries(query)) {
    switch (key) {
      case SearchFiltersTypes.CATEGORY:
        if (value == 'property' || value == 'compound')
          dispatch({
            type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
            payload: {
              name: key,
              value: value
            }
          });
        break;
      case SearchFiltersTypes.MIN_PRICE:
      case SearchFiltersTypes.MAX_PRICE:
      case SearchFiltersTypes.MIN_UNIT_AREA:
      case SearchFiltersTypes.MAX_UNIT_AREA:
      case SearchFiltersTypes.DOWN_PAYMENT:
      case SearchFiltersTypes.MONTHLY_INSTALLMENTS:
        if (!isNaN(value))
          dispatch({
            type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
            payload: {
              name: key,
              value: parseInt(value)
            }
          });
        break;
      case 'page_number':
        dispatch({
          type: SearchFiltersActionTypes.UPDATE_PAGE_NUMBER,
          payload: {
            name: key,
            value: parseInt(value)
          }
        });
        break;
      case SearchFiltersTypes.DELIVERY_DATE:
        if (isValidDate(value)) {
          dispatch({
            type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
            payload: {
              name: key,
              value: value
            }
          });
        }
        break;
      case SearchFiltersTypes.COMPOUNDS:
      case SearchFiltersTypes.AREAS:
      case SearchFiltersTypes.DEVELOPERS:
      case SearchFiltersTypes.PROPERTY_TYPES:
      case SearchFiltersTypes.AMENITIES:
      case SearchFiltersTypes.BEDROOMS:
      case SearchFiltersTypes.BATHROOMS:
      case SearchFiltersTypes.INSTALLMENT_YEARS:
      case SearchFiltersTypes.DELIVERY_YEARS:
        value &&
          dispatch({
            type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
            payload: {
              name: key,
              value: convertStringArrayToNumberArray(
                value.toString().split(',')
              )
            }
          });
        break;
      case SearchFiltersTypes.COMPLETION_TYPE:
      case SearchFiltersTypes.SALE_TYPE:
        value &&
          dispatch({
            type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
            payload: {
              name: key,
              value: value.toString().split(',')
            }
          });
        break;
      case SearchFiltersTypes.SORT_BY:
        value &&
          dispatch({
            type: SearchFiltersActionTypes.UPDATE_SORTING_ORDER,
            payload: {
              order_by: value.split(',')[0],
              order_direction: value.split(',')[1]
            }
          });
        break;
      case SearchFiltersTypes.NAWY_NOW:
        if (value === 'true' || value === 'false')
          dispatch({
            type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
            payload: {
              name: key,
              value: Boolean(value)
            }
          });
        break;
    }
  }
};

export const mapSearchFiltersKeysToSearchApiKeys = (
  searchFilterKey: SearchFiltersTypes
) => {
  switch (searchFilterKey) {
    case SearchFiltersTypes.CATEGORY:
      return 'show';
    case SearchFiltersTypes.COMPOUNDS:
      return 'compounds_ids';
    case SearchFiltersTypes.AREAS:
      return 'areas_ids';
    case SearchFiltersTypes.DEVELOPERS:
      return 'developers_ids';
    case SearchFiltersTypes.PROPERTY_TYPES:
      return 'property_type_id';
    case SearchFiltersTypes.AMENITIES:
      return 'amenities';
    case SearchFiltersTypes.BEDROOMS:
      return 'number_of_bedrooms';
    case SearchFiltersTypes.BATHROOMS:
      return 'number_of_bathrooms';
    case SearchFiltersTypes.COMPLETION_TYPE:
      return 'finishing';
    case SearchFiltersTypes.DELIVERY_DATE:
      return 'min_ready_by';
    case SearchFiltersTypes.DELIVERY_YEARS:
      return 'ready_by_years';
    case SearchFiltersTypes.DOWN_PAYMENT:
      return 'max_down_payment';
    case SearchFiltersTypes.MONTHLY_INSTALLMENTS:
      return 'max_installments';
    case SearchFiltersTypes.SALE_TYPE:
      return 'resale';
    case 'page_number' as any:
      return 'start';
    default:
      return searchFilterKey;
  }
};

export const mapSearchFiltersKeysToCompoundSearchApiKeys: any = (
  searchFilterKey: SearchFiltersTypes
) => {
  switch (searchFilterKey) {
    case SearchFiltersTypes.COMPOUNDS:
      return 'compoundsIds';
    case SearchFiltersTypes.AREAS:
      return 'areaIds';
    case SearchFiltersTypes.DEVELOPERS:
      return 'developerIds';
    case SearchFiltersTypes.PROPERTY_TYPES:
      return 'propertyTypeIds';
    case SearchFiltersTypes.AMENITIES:
      return 'propertyAmenityIds';
    case SearchFiltersTypes.BEDROOMS:
      return 'numberOfBedrooms';
    case SearchFiltersTypes.BATHROOMS:
      return 'numberOfBathrooms';
    case SearchFiltersTypes.COMPLETION_TYPE:
      return 'finishing';
    case SearchFiltersTypes.DELIVERY_DATE:
      return 'minReadyBy';
    case SearchFiltersTypes.DELIVERY_YEARS:
      return 'readyBy';
    case SearchFiltersTypes.DOWN_PAYMENT:
      return 'maxDownPayment';
    case SearchFiltersTypes.MONTHLY_INSTALLMENTS:
      return 'maxInstallments';
    case SearchFiltersTypes.MIN_PRICE:
      return 'minPrice';
    case SearchFiltersTypes.MAX_PRICE:
      return 'maxPrice';
    case SearchFiltersTypes.MIN_UNIT_AREA:
      return 'minUnitArea';
    case SearchFiltersTypes.MAX_UNIT_AREA:
      return 'maxUnitArea';
    case SearchFiltersTypes.SALE_TYPE:
      return 'resaleType';
    case SearchFiltersTypes.NAWY_NOW:
      return 'isNawyNow';
    case SearchFiltersTypes.HAS_GARDEN:
      return 'hasGarden';
    case SearchFiltersTypes.HAS_ROOF:
      return 'hasRoof';
    case SearchFiltersTypes.INSTALLMENT_YEARS:
      return 'installmentYears';
    case SearchFiltersTypes.SORT_BY:
      return 'orderBy';
    default:
      return searchFilterKey;
  }
};

export const mapSearchFiltersValuesToSearchApiValues = (
  searchFilterKey: SearchFiltersTypes,
  searchFilterValue: any
) => {
  switch (searchFilterKey) {
    case SearchFiltersTypes.BEDROOMS:
    case SearchFiltersTypes.BATHROOMS:
      return formatSearchValuesWithGreaterThan(searchFilterValue, 5);
    case SearchFiltersTypes.INSTALLMENT_YEARS:
      return formatSearchValuesWithGreaterThan(searchFilterValue, 6);
    case SearchFiltersTypes.DELIVERY_DATE:
      return searchFilterValue
        ? {
            date: searchFilterValue.split('-'),
            exclude_movenow: false
          }
        : undefined;
    case 'page_number' as any:
      return (searchFilterValue - 1) * 12 + 1;

    default:
      return searchFilterValue;
  }
};

export const resetFilter = (
  filterType:
    | SearchFiltersTypes
    | { min: SearchFiltersTypes; max: SearchFiltersTypes }
    | { delivery_date: SearchFiltersTypes; delivery_years: SearchFiltersTypes }
    | {
        down_payment: SearchFiltersTypes;
        monthly_installments: SearchFiltersTypes;
        installment_years: SearchFiltersTypes;
      },

  dispatch: any,
  router: NextRouter
) => {
  const isRangeFilter = (
    filter: any
  ): filter is { min: SearchFiltersTypes; max: SearchFiltersTypes } => {
    return filter.min !== undefined && filter.max !== undefined;
  };
  switch (filterType) {
    case SearchFiltersTypes.COMPOUNDS:
    case SearchFiltersTypes.BEDROOMS:
    case SearchFiltersTypes.BATHROOMS:
    case SearchFiltersTypes.AREAS:
    case SearchFiltersTypes.DEVELOPERS:
    case SearchFiltersTypes.PROPERTY_TYPES:
    case SearchFiltersTypes.COMPLETION_TYPE:
    case SearchFiltersTypes.AMENITIES:
    case SearchFiltersTypes.SALE_TYPE:
    case SearchFiltersTypes.AREAS:
    case SearchFiltersTypes.DEVELOPERS:
    case SearchFiltersTypes.DELIVERY_YEARS:
      dispatch({
        type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
        payload: {
          name: filterType,
          value: []
        }
      });
      updateQuery(router, [], [filterType]);
      break;

    case SearchFiltersTypes.DELIVERY_DATE:
      dispatch({
        type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
        payload: {
          name: filterType,
          value: undefined
        }
      });
      updateQuery(router, [], [filterType]);
      break;
    case SearchFiltersTypes.SORT_BY:
      updateQuery(router, [], ['order_by', 'order_direction', 'sort_by']);
      dispatch({
        type: SearchFiltersActionTypes.UPDATE_SORTING_ORDER,
        payload: {
          name: filterType,
          value: { order_by: '', order_direction: '' }
        }
      });
      break;
    default:
      if (isRangeFilter(filterType)) {
        dispatch({
          type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
          payload: {
            name: filterType.min,
            value: undefined
          }
        });
        dispatch({
          type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
          payload: {
            name: filterType.max,
            value: undefined
          }
        });
        updateQuery(router, [], [filterType.min, filterType.max]);
      } else if (
        isEqual(filterType, {
          delivery_date: SearchFiltersTypes.DELIVERY_DATE,
          delivery_years: SearchFiltersTypes.DELIVERY_YEARS
        })
      ) {
        dispatch({
          type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
          payload: {
            name: SearchFiltersTypes.DELIVERY_DATE,
            value: undefined
          }
        });
        dispatch({
          type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
          payload: {
            name: SearchFiltersTypes.DELIVERY_YEARS,
            value: []
          }
        });
        updateQuery(
          router,
          [],
          [SearchFiltersTypes.DELIVERY_DATE, SearchFiltersTypes.DELIVERY_YEARS]
        );
      } else {
        dispatch({
          type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
          payload: {
            name: SearchFiltersTypes.MONTHLY_INSTALLMENTS,
            value: undefined
          }
        });
        dispatch({
          type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
          payload: {
            name: SearchFiltersTypes.DOWN_PAYMENT,
            value: undefined
          }
        });
        dispatch({
          type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
          payload: {
            name: SearchFiltersTypes.INSTALLMENT_YEARS,
            value: []
          }
        });
        updateQuery(
          router,
          [],
          [
            SearchFiltersTypes.INSTALLMENT_YEARS,
            SearchFiltersTypes.DOWN_PAYMENT,
            SearchFiltersTypes.MONTHLY_INSTALLMENTS
          ]
        );
      }
      break;
  }
};

export const handleSelectOptionsWithMaxValue = (
  options: InputOption[],
  maxDisplayedValue: number | undefined,
  setNewOptions: any
) => {
  if (maxDisplayedValue) {
    setNewOptions((): InputOption[] => {
      const res = options.filter((curr: InputOption) => {
        const num = curr.value.toString().split('-')[0];
        if (parseInt(num) < maxDisplayedValue) {
          return curr;
        } else if (parseInt(num) == new Date().getFullYear()) {
          return curr;
        } else if (parseInt(num) == maxDisplayedValue) {
          const res = curr['options'].displayedValue + '+';
          const arr = Array.from(res);
          let duplicated = arr.filter(item => item != '+');
          duplicated.push('+');

          let thedisplayed = '';
          duplicated.map(curr => {
            thedisplayed += curr;
          });
          curr['options'].displayedValue = thedisplayed;
          return curr;
        }
      });
      return res;
    });
  } else {
    setNewOptions(options);
  }
};

export const formatSearchValuesWithGreaterThan = (
  arr: number[] | undefined,
  greaterThan: number
) => {
  if (!arr) return undefined;
  const greaterThanIndex = arr.findIndex(val => val === greaterThan);
  if (greaterThanIndex == -1)
    return {
      exact: arr
    };

  const newArr = arr.filter(value => {
    return value != greaterThan;
  });

  return {
    ...(newArr.length > 0 && { exact: [...newArr] }),
    greater_than: greaterThan
  };
};

export const resetFilters = (
  router: NextRouter,
  searchFiltersDispatch: Dispatch<SearchFiltersActions>,
  shallow?: boolean
) => {
  searchFiltersDispatch({
    type: SearchFiltersActionTypes.RESET
  });

  const { query, push } = router;
  delete query.device;
  Object.keys(query).forEach(queryParam => {
    if (router.pathname.includes(queryParam)) {
      delete query[queryParam];
    }
  });
  // to redirect to search page when you reset filters from buy SEO page
  let pathname = router.asPath;
  if (router.asPath.includes('/buy')) {
    pathname = '/search';
    return;
  }

  push(
    {
      query: {
        ...(router.query.compounds &&
          !router.asPath.includes('/nawy-now') &&
          !router.asPath.includes('/affordability-results') && {
            compounds: router.query.compounds
          }),
        ...(router.query.view && { view: router.query.view })
      },
      pathname: pathname
        .split('?')[0]
        .replace('(/desktop|/mobile|/undefined)', '')
    },
    undefined,
    { shallow: shallow ?? true }
  );
  // }
};

export const handleChangePage = (
  loadedResultsLength: number,
  searchFiltersDispatch: Dispatch<SearchFiltersActions>
) => {
  searchFiltersDispatch({
    type: SearchFiltersActionTypes.UPDATE_PAGE_NUMBER,
    payload: {
      name: 'page_number',
      value: Math.ceil(loadedResultsLength / 12 + 1)
    }
  });
};

export const handleGetNeighborAreas = async (
  searchFiltersState: SearchFiltersInterface,
  page: number,
  queryAreas?: number[],
  queryPropertyTypes?: number[],
  locale?: string
) => {
  const neighborAreas = await AreasApis.getNeighboringAreas(
    queryAreas || searchFiltersState.searchFilters.areas
  ).then(res => {
    return res.map((area: { name: string; id: number }) => area.id);
  });
  const newSearchBody = {
    ...searchFiltersState.searchBody,
    areas_ids: neighborAreas,
    ...(queryPropertyTypes && { property_type_id: queryPropertyTypes }),
    ...(searchFiltersState.searchFilters?.property_types.length > 0 && {
      property_type_id: searchFiltersState.searchFilters?.property_types
    }),
    start: Math.ceil(page * 12 + 1)
  };
  if (neighborAreas?.length > 0) {
    return await fetchSearchResults(
      removeNullUndefinedEmptyFromObject(newSearchBody),
      locale
    );
  }
};

export const filterEventMap = (filterName: string) => {
  switch (filterName) {
    case 'bedrooms':
      return 'beds';
    case 'bathrooms':
      return 'baths';
    case 'finishing':
      return 'finish_type';
    case 'installment_years':
      return 'install_yrs';
    case 'down_payment':
      return 'max_dwn_pymnt';
    case 'monthly_installments':
      return 'max_monthly_install';
    default:
      return filterName;
  }
};

export const constructNewSsrSearchBody = (query: any) => {
  let body: any = { page: query?.page_number ?? 1, pageSize: 12 };

  for (const [key, value] of Object.entries(query) as any) {
    switch (key) {
      case SearchFiltersTypes.COMPOUNDS:
      case SearchFiltersTypes.AREAS:
      case SearchFiltersTypes.DEVELOPERS:
      case SearchFiltersTypes.PROPERTY_TYPES:
      case SearchFiltersTypes.AMENITIES:
      case SearchFiltersTypes.BEDROOMS:
      case SearchFiltersTypes.BATHROOMS:
      case SearchFiltersTypes.INSTALLMENT_YEARS:
      case SearchFiltersTypes.DELIVERY_YEARS:
        let values = convertStringArrayToNumberArray(
          value.toString().split(',')
        );
        body = {
          ...body,
          [mapSearchFiltersKeysToCompoundSearchApiKeys(key)]: values
        };
        break;

      case SearchFiltersTypes.MIN_PRICE:
      case SearchFiltersTypes.MAX_PRICE:
      case SearchFiltersTypes.MIN_UNIT_AREA:
      case SearchFiltersTypes.MAX_UNIT_AREA:
      case SearchFiltersTypes.DOWN_PAYMENT:
      case SearchFiltersTypes.MONTHLY_INSTALLMENTS:
        if (!isNaN(value)) {
          body = {
            ...body,
            [mapSearchFiltersKeysToCompoundSearchApiKeys(key)]: parseInt(value)
          };
        }
        break;

      case SearchFiltersTypes.HAS_GARDEN:
      case SearchFiltersTypes.HAS_ROOF:
      case SearchFiltersTypes.NAWY_NOW:
        if (value === 'true' || value === 'false')
          body = {
            ...body,
            [mapSearchFiltersKeysToCompoundSearchApiKeys(key)]: Boolean(value)
          };
        break;

      case SearchFiltersTypes.COMPLETION_TYPE:
      case SearchFiltersTypes.SALE_TYPE:
        body = {
          ...body,
          [mapSearchFiltersKeysToCompoundSearchApiKeys(key)]: value
            .toString()
            .split(',')
        };
        break;
      case SearchFiltersTypes.DELIVERY_DATE:
        if (isValidDate(value) && typeof value === 'string') {
          const newValue = new Date(value).getFullYear();

          body = {
            ...body,
            [mapSearchFiltersKeysToCompoundSearchApiKeys(key)]: newValue
          };
        }
        break;
      case SearchFiltersTypes.SORT_BY:
        let newValue;
        if (value) {
          newValue = `${value.split(',')[0]}:${value.split(',')[1]}`;
        }
        if (query.category !== 'property' || !query.category) {
          body = {
            ...body,
            [mapSearchFiltersKeysToCompoundSearchApiKeys(key)]: newValue
          };
        }
        break;
    }
  }

  return body;
};

export const constructSsrSearchBody = (query: any) => {
  let body: any = { show: query?.category };
  // if (Object.keys(query).length == 0) return { show: 'compound' };
  for (const [key, value] of Object.entries(query) as any) {
    switch (key) {
      case SearchFiltersTypes.CATEGORY:
        if (value == 'property' || 'compound') {
          body = {
            ...body,
            [mapSearchFiltersKeysToSearchApiKeys(key)]: value
          };
          break;
        }
        break;
      case SearchFiltersTypes.BEDROOMS:
      case SearchFiltersTypes.BATHROOMS:
      case SearchFiltersTypes.INSTALLMENT_YEARS:
        const result = value
          ?.toString()
          .split(',')
          .map((val: string) => parseInt(val));

        body = {
          ...body,
          [mapSearchFiltersKeysToSearchApiKeys(key)]:
            formatSearchValuesWithGreaterThan(result, 6)
        };
        break;
      case SearchFiltersTypes.COMPOUNDS:
      case SearchFiltersTypes.AREAS:
      case SearchFiltersTypes.DEVELOPERS:
      case SearchFiltersTypes.PROPERTY_TYPES:
      case SearchFiltersTypes.AMENITIES:
      case SearchFiltersTypes.DELIVERY_YEARS:
        {
          let values = convertStringArrayToNumberArray(
            value.toString().split(',')
          );
          body = {
            ...body,
            [mapSearchFiltersKeysToSearchApiKeys(key as any)]: values
          };
        }
        break;
      case SearchFiltersTypes.MIN_PRICE:
      case SearchFiltersTypes.MAX_PRICE:
      case SearchFiltersTypes.MIN_UNIT_AREA:
      case SearchFiltersTypes.MAX_UNIT_AREA:
      case SearchFiltersTypes.DOWN_PAYMENT:
      case SearchFiltersTypes.MONTHLY_INSTALLMENTS:
        if (!isNaN(value)) {
          body = {
            ...body,
            [mapSearchFiltersKeysToSearchApiKeys(key)]: parseInt(value)
          };
        }
        break;
      case 'page_number':
        if (!isNaN(value)) {
          const newValue = (parseInt(value) - 1) * 12 + 1;
          body = {
            ...body,
            [mapSearchFiltersKeysToSearchApiKeys(key)]: newValue
          };
        }
        break;

      case SearchFiltersTypes.DELIVERY_DATE:
        if (isValidDate(value))
          body = {
            ...body,
            [mapSearchFiltersKeysToSearchApiKeys(key)]: {
              date: value.split('-'),
              exclude_movenow: false
            }
          };
        break;
      case SearchFiltersTypes.COMPLETION_TYPE:
      case SearchFiltersTypes.SALE_TYPE:
        body = {
          ...body,
          [mapSearchFiltersKeysToSearchApiKeys(key)]: value
            .toString()
            .split(',')
        };
        break;
      case SearchFiltersTypes.SORT_BY:
        body = {
          ...body,
          order_by: value.split(',')[0],
          order_direction: value.split(',')[1]
        };
        break;
      case SearchFiltersTypes.NAWY_NOW:
        if (value === 'true' || value === 'false')
          body = {
            ...body,
            [mapSearchFiltersKeysToSearchApiKeys(key)]: Boolean(value)
          };
        break;
    }
  }
  return body;
};
