import { useContext, useState } from 'react';
import styled from 'styled-components';
import useOnClickOutside from '../../../../lib/Custom Hooks/onOutsideClick';
import { commifyNumber } from '../../../../lib/helpers';
import { useTranslation } from 'next-i18next';
import ButtonIcon from '../../../../new-components/ui/ButtonIcon';
import CloseIcon from '../../../../new-components/svg-icons/CloseIcon';
import { useRouter } from 'next/router';
import { SearchFiltersActionTypes } from '../../../../reducers/search-filters/actions';
import { updateQuery } from '../../../../helpers/common';
import { SearchFiltersTypes } from '../../../../reducers/search-filters/reducer';
import {
  SearchContext,
  SearchContextInterface
} from '../../../search-new/context/SearchContext';

interface DisplayedValue {
  name: SearchFiltersTypes;
  value: number | undefined | number[];
  preValue: string;
  postValue: string;
  filterAction: SearchFiltersActionTypes;
}

interface ContainerProps {
  name?: string;
  children: React.ReactNode;
  subText?: string;
  containerRef?: any;
  displayedValues?: DisplayedValue[];
}

interface ContainerFilters {
  name: string;
  value: any;
  preValue: string;
  postValue: string;
  filterAction: SearchFiltersActionTypes;
}

const FilterContainer = ({
  name,
  subText,
  children,
  containerRef,
  displayedValues
}: ContainerProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { t } = useTranslation('search');

  const { searchFiltersDispatch } = useContext(
    SearchContext
  ) as SearchContextInterface;
  const router = useRouter();

  const handleClearFilter = (filterName: any, filterType: any) => {
    if (
      filterType == SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT
    ) {
      searchFiltersDispatch({
        type: filterType,
        payload: {
          name: filterName,
          value: []
        }
      });
    } else {
      searchFiltersDispatch({
        type: filterType,
        payload: {
          name: filterName,
          value: undefined
        }
      });
    }
    updateQuery(router, undefined, [filterName]);
  };

  const openFilter = (e: any) => {
    if (!e.target.classList.contains('close-btn')) {
      setIsOpen(true);
    }
  };
  const closeFilter = () => {
    if (containerRef?.current) {
      setIsOpen(false);
    }
  };

  useOnClickOutside(containerRef, containerRef, () => {
    if (displayedValues?.filter(item => item.value).length != 0) {
      setTimeout(closeFilter, 300);
    }
  });

  return (
    <>
      <Container>
        {isOpen ? (
          <div
            ref={containerRef}
            className="filter-wrapper"
            onClick={e => openFilter(e)}
          >
            <h2>
              {name} <span className="text-1">{subText}</span>
            </h2>
            {children}
          </div>
        ) : (
          <div
            ref={containerRef}
            className="filter-wrapper closed-filter"
            onClick={e => openFilter(e)}
          >
            <h2>
              {name} <span className="text-1">{subText}</span>
            </h2>
            <div className="selected-values">
              {displayedValues ? (
                displayedValues.map(
                  (value: ContainerFilters, index: React.Key) => (
                    <>
                      {value.value != undefined ? (
                        <div className="displayed-filter" key={value.value}>
                          <div className="filter-data">
                            <span className="text-1">{t(value.preValue)}</span>
                            <h4 className="text-3">
                              {`${commifyNumber(value.value)} ${
                                value.postValue
                              }`}{' '}
                            </h4>
                          </div>
                          <ButtonIcon
                            containerClass="btn-container"
                            btnClass="close-btn"
                            icon={<CloseIcon />}
                            backgroundColor="white"
                            color="black"
                            onClick={() =>
                              handleClearFilter(value.name, value.filterAction)
                            }
                            border="none"
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )
                )
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

const Container = styled.div`
  .filter-wrapper {
    width: 100%;
    min-height: 57px;
    background-color: ${props => props.theme.light.colors.lightGrey};
    border-radius: 8px;
    margin-bottom: 16px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-out;
    h2 {
      margin-bottom: 8px;
      font-size: ${props => props.theme.font.sizes.l};
      font-family: materialBold;
      color: ${props => props.theme.light.colors.black};

      span {
        font-family: materialRegular;
        font-size: ${props => props.theme.font.sizes.xxs};
      }
    }
    &.closed-filter {
      height: auto;
      transition: all 0.2s ease-out;
      &:hover {
        background-color: ${props => props.theme.light.borderColors.primary};
      }

      .selected-values {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5px;

        .displayed-filter {
          padding: 5px;
          background-color: ${props => props.theme.light.colors.secondary};
          border-radius: 7.5px;
          width: max-content;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          justify-content: space-between;

          .filter-data {
            display: flex;
            flex-direction: column;
            h4 {
              font-size: ${props => props.theme.font.sizes.s};
            }
            span {
              font-size: ${props => props.theme.font.sizes.xxs};
              font-family: materialRegular;
            }
          }

          &.number-label {
            min-width: 120px;
            justify-content: space-between;
          }
          .btn-container {
            height: 18.5px;
            width: 18.5px;

            button {
              width: 100%;
              height: 100%;
              svg {
                pointer-events: none;
                width: 8.5px;
                height: 8px;
              }
            }
          }
          span {
            font-size: 12px;
            color: white;
          }
          h4 {
            color: white;
          }
        }
      }
    }

    .installment-wrapper {
      display: flex;
      flex-direction: row;
      > div {
        width: 50%;
      }
    }
  }

  @media screen and (max-width: ${props =>
      props.theme.breakPoints.mobile.max}) {
    .filter-wrapper {
      padding: 0;
      h2 {
        font-size: 17px;
        color: #1a2028;
      }
    }
  }
`;

export default FilterContainer;
