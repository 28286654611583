import { createSlice } from '@reduxjs/toolkit';
interface StateType {
  compoundFavorites: number[];
  propertyFavorites: number[];
}

interface RemoveAction {
  payload: number;
}
interface WatchListPayload {
  payload: {
    properties: number[];
    compounds: number[];
  };
}
interface AddAction {
  payload: number;
}
const initialState = {
  compoundFavorites: [],
  propertyFavorites: []
} as StateType;
export const favoriteSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    addPropertyToFavorites(state: StateType, action: AddAction) {
      state.propertyFavorites = [...state.propertyFavorites, action.payload];
    },
    addCompoundToFavorites(state: StateType, action: AddAction) {
      state.compoundFavorites = [...state.compoundFavorites, action.payload];
    },
    removePropertyFromFavorites(state: StateType, action: RemoveAction) {
      state.propertyFavorites = state.propertyFavorites.filter(
        id => id !== action.payload
      );
    },
    removeCompoundFromFavorites(state: StateType, action: RemoveAction) {
      state.compoundFavorites = state.compoundFavorites.filter(
        id => id !== action.payload
      );
    },
    addAllWatchList(state: StateType, action: WatchListPayload) {
      state.propertyFavorites = action.payload.properties;
      state.compoundFavorites = action.payload.compounds;
    }
  }
});
export const {
  removePropertyFromFavorites,
  removeCompoundFromFavorites,
  addPropertyToFavorites,
  addCompoundToFavorites,
  addAllWatchList
} = favoriteSlice.actions;

export default favoriteSlice.reducer;
