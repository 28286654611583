import { createSlice } from '@reduxjs/toolkit';
import { MapConfigDto } from '../../features/map/dtos';
import {
  Area,
  Compound,
  MapFilters,
  MapMode,
  MapSearchStates,
  QuickFilter,
  ResultsType
} from '../../features/map/types';
import { SearchViewTypes } from '../../new-lib/constants/common';

interface InitialState {
  config: MapConfigDto | null;
  areas: Area[];
  viewBounds: {
    topLeft: { lat: number; long: number };
    bottomRight: { lat: number; long: number };
  } | null;
  compoundViewBounds: {
    topLeft: { lat: number; long: number };
    bottomRight: { lat: number; long: number };
  } | null;
  mapMode: MapMode;
  resultsType: ResultsType;
  compounds: Compound[];
  selectedCompound: Compound | null;
  drawerOpen: boolean;
  selectedArea: Area | null;
  filters: MapFilters;
  quickFilter: QuickFilter | null;
  drawerPosition: number;
  searchView: SearchViewTypes;
  areaLoaded: boolean;
  searchStates: MapSearchStates;
  drawerFirstOpened: boolean;
  showRecenterButton: boolean;
  sahelMode: boolean;
  firstDestination: Compound | null;
  secondDestination: Compound | null;
  showCalculateDistanceTooltip: boolean;
  sahelModal: boolean;
  searchDrawerOpen: boolean;
}
const initialState: InitialState = {
  config: null,
  areas: [],
  viewBounds: null,
  compoundViewBounds: null,
  mapMode: MapMode.SEARCH,
  resultsType: ResultsType.AREAS,
  compounds: [],
  drawerOpen: false,
  selectedCompound: null,
  quickFilter: null,
  selectedArea: null,
  filters: {},
  drawerPosition: 20,
  searchView: SearchViewTypes.MAP,
  areaLoaded: false,
  searchStates: {
    isLoading: false,
    isFetching: false,
    isError: false
  },
  drawerFirstOpened: false,
  showRecenterButton: false,
  sahelMode: false,
  firstDestination: null,
  secondDestination: null,
  showCalculateDistanceTooltip: true,
  sahelModal: true,
  searchDrawerOpen: true
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setMapConfig(state, action) {
      state.config = action.payload;
    },
    setMapAreas(state, action) {
      state.areas = action.payload;
    },
    setMapCompounds(state, action) {
      state.compounds = action.payload;
    },
    setViewBounds(state, action) {
      state.viewBounds = action.payload;
    },
    setCompoundViewBounds(state, action) {
      state.compoundViewBounds = action.payload;
    },
    setResultsType(state, action) {
      state.resultsType = action.payload;
    },
    setSelectedCompound(state, action) {
      state.selectedCompound = action.payload;
    },
    setSelectedArea(state, action) {
      state.selectedArea = action.payload;
    },
    setMapFilters(state, action) {
      state.filters = action.payload;
    },
    setDrawerOpen(state, action) {
      state.drawerOpen = action.payload;
    },
    setQuickFilter(state, action) {
      if (state.quickFilter === action.payload) {
        state.quickFilter = null;
      } else {
        state.quickFilter = action.payload;
      }
    },
    setDrawerPosition(state, action) {
      state.drawerPosition = action.payload;
    },
    setSearchView(state, action) {
      state.searchView = action.payload;
    },
    setAreaLoaded(state, action) {
      state.areaLoaded = action.payload;
    },
    setMapSearchStates(state, action) {
      state.searchStates = action.payload;
    },
    setDrawerFirstOpened(state, action) {
      state.drawerFirstOpened = action.payload;
    },
    setShowRecenterButton(state, action) {
      state.showRecenterButton = action.payload;
    },
    setSahelMode(state, action) {
      state.sahelMode = action.payload;
    },
    setShowCalculateDistanceTooltip(state, action) {
      state.showCalculateDistanceTooltip = action.payload;
    },
    setModal(state, action) {
      state.sahelModal = action.payload;
    },
    setMapMode(state, action) {
      if (
        state.mapMode === MapMode.CalculateDistance &&
        action.payload !== MapMode.CalculateDistance
      ) {
        state.firstDestination = null;
        state.secondDestination = null;
      }

      state.mapMode = action.payload;
    },
    setSearchDrawerOpen(state, action) {
      state.searchDrawerOpen = action.payload;
    },
    setFirstDestination(state, action) {
      state.firstDestination = action.payload;
    },
    setSecondDestination(state, action) {
      state.secondDestination = action.payload;
    }
  }
});

export const {
  setMapConfig,
  setMapAreas,
  setMapCompounds,
  setViewBounds,
  setCompoundViewBounds,
  setMapMode,
  setSelectedCompound,
  setResultsType,
  setSelectedArea,
  setMapFilters,
  setDrawerOpen,
  setQuickFilter,
  setDrawerPosition,
  setSearchView,
  setAreaLoaded,
  setMapSearchStates,
  setDrawerFirstOpened,
  setSahelMode,
  setFirstDestination,
  setSecondDestination,
  setShowCalculateDistanceTooltip,
  setModal,
  setSearchDrawerOpen
} = mapSlice.actions;

export default mapSlice.reducer;
