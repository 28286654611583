interface RemarketingEventData {
  listingIds: number[];
  listingPrice?: number;
}

const googleAdsAccounts = [
  'AW-877812802',
  'AW-11334367330',
  'AW-11334382153',
  'AW-11334416028',
  'AW-11334475571'
];

const safeRequestIdleCallback = (callback: IdleRequestCallback) => {
  if ('requestIdleCallback' in window) {
    requestIdleCallback(callback);
  } else {
    // Fallback: Execute the callback after a small timeout
    setTimeout(callback, 0);
  }
};

const wrapper = (
  key: 'mixpanel' | 'gtag' | 'moengage' | 'fbq' | 'ttq',
  func: () => void
) => {
  if (typeof window === 'undefined') return; // Prevent server-side execution

  const onLoaded = () => {
    if (func) {
      try {
        func();
      } catch {}
    }
    window.removeEventListener(key, onLoaded); // Cleanup
  };

  switch (key) {
    case 'ttq':
      if (typeof window.ttq.identify === 'function') {
        onLoaded();
      } else {
        window.addEventListener(`${key}Loaded`, onLoaded);
      }
      break;
    case 'fbq':
      if (typeof fbq === 'function') {
        onLoaded();
      } else {
        window.addEventListener(`${key}Loaded`, onLoaded);
      }
      break;
    case 'gtag':
      if (typeof gtag === 'function') {
        onLoaded();
      } else {
        window.addEventListener(`${key}Loaded`, onLoaded);
      }
      break;
    case 'mixpanel':
      if (!func) {
        return;
      }
      try {
        func();
      } catch {}
      break;
    case 'moengage':
      if (typeof window.Moengage?.track_event === 'function') {
        onLoaded();
      } else {
        window.addEventListener(`${key}Loaded`, onLoaded);
      }
      break;
  }
};

export const trackEvent = {
  viewContent: ({ listingIds, listingPrice }: RemarketingEventData) => {
    safeRequestIdleCallback(() => {
      wrapper('fbq', () => {
        fbq('track', 'ViewContent', {
          content_type: 'home_listing',
          content_ids: listingIds,
          currency: 'EGP',
          value: listingPrice
        });
      });
      wrapper('gtag', () => {
        gtag('event', 'view_item_list', {
          send_to: googleAdsAccounts,
          value: listingPrice,
          items: listingIds.map(listing => ({
            id: listing,
            google_business_vertical: 'real_estate'
          }))
        });
      });
    });
  },

  initiateCheckout: ({ listingIds, listingPrice }: RemarketingEventData) => {
    safeRequestIdleCallback(() => {
      wrapper('fbq', () => {
        fbq('track', 'InitiateCheckout', {
          content_type: 'home_listing',
          content_ids: listingIds,
          currency: 'EGP',
          value: listingPrice
        });
      });
      wrapper('gtag', () => {
        gtag('event', 'add_to_cart', {
          send_to: googleAdsAccounts,
          value: listingPrice,
          items: listingIds.map(listing => ({
            id: listing,
            google_business_vertical: 'real_estate'
          }))
        });
      });
    });
  },

  purchase: ({ listingIds, listingPrice }: RemarketingEventData) => {
    safeRequestIdleCallback(() => {
      wrapper('fbq', () => {
        fbq('track', 'Purchase', {
          content_type: 'home_listing',
          content_ids: listingIds,
          currency: 'EGP',
          value: listingPrice
        });
      });
      wrapper('gtag', () => {
        gtag('event', 'purchase', {
          send_to: googleAdsAccounts,
          value: listingPrice,
          items: listingIds.map(listing => ({
            id: listing,
            google_business_vertical: 'real_estate'
          }))
        });
      });
    });
  },

  search: ({ listingIds }: RemarketingEventData) => {
    safeRequestIdleCallback(() => {
      wrapper('fbq', () => {
        fbq('track', 'Search', {
          content_type: 'home_listing',
          content_ids: listingIds,
          country: 'Egypt'
        });
      });
      wrapper('gtag', () => {
        gtag('event', 'view_search_results', {
          send_to: googleAdsAccounts,
          items: listingIds?.map(listing => ({
            id: listing,
            google_business_vertical: 'real_estate'
          }))
        });
      });
    });
  }
};
