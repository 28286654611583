import { Corporation, Organization } from 'schema-dts';
import { JsonLd } from './JsonLD';

const OrganizationSchema = (props: { locale?: string }) => {
  const organizationEn: any = {
    '@type': ['Organization', 'Corporation'],
    name: 'Nawy Real Estate', // organization name
    url: process.env.NEXT_PUBLIC_HOST, // url
    logo: 'https://www.nawy.com/assets/icons/common/nawy.svg', //logo url
    description:
      'Nawy is a leading real estate platform in Egypt, offering innovative property solutions for buyers, sellers, and investors.',
    sameAs: [
      'https://www.facebook.com/nawyrealestate',
      'https://www.linkedin.com/company/nawyestate/',
      'https://www.youtube.com/channel/UCAtydzjr9JcWM4UNXU1AXig',
      'https://www.instagram.com/nawyrealestate/',
      'https://x.com/nawyegypt'
    ],
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+201029208551',
      email: 'info@nawy.com',
      contactType: 'customer service',
      areaServed: 'EG',
      availableLanguage: 'en'
    },
    address: {
      '@type': 'PostalAddress',
      streetAddress: '47North 90 Street',
      addressLocality: 'New cairo',
      addressRegion: 'Cairo',
      postalCode: '12345',
      addressCountry: 'EG'
    },
    foundingDate: '2016',
    founder: {
      '@type': 'Person',
      name: 'Mostafa Beltagy',
      jobTitle: 'CEO'
    },
    areaServed: {
      '@type': 'AdministrativeArea',
      name: 'Egypt'
    },
    brand: {
      '@type': 'Brand',
      name: 'Nawy',
      description: 'A trusted name in real estate innovation in Egypt.',
      logo: 'https://www.nawy.com/assets/icons/common/nawy.svg' // logo url
    },
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: 'https://www.nawy.com/search?q={search_term_string}'
      },
      query: 'required name=search_term_string'
    },
    keywords:
      'real estate, Egypt, property, buy property, sell property, real estate investment'
  };
  const organizationAr: any = {
    '@type': ['Organization', 'Corporation'],
    name: 'ناوي للعقارات',
    url: 'https://www.nawy.com/ar/',
    logo: 'https://www.nawy.com/assets/icons/common/nawy.svg',
    description:
      'Nawy أفضل وسيط عقاري في مصر. ابحث وقارن بين مجموعة من الوحدات للبيع داخل كمبوندات القاهرة الجديدة وأكتوبر والعاصمة الإدارية والساحل الشمالي وغيرها واحجز وحدتك الآن',
    sameAs: [
      'https://www.facebook.com/nawyrealestate',
      'https://www.linkedin.com/company/nawyestate/',
      'https://www.youtube.com/channel/UCAtydzjr9JcWM4UNXU1AXig',
      'https://www.instagram.com/nawyrealestate/',
      'https://x.com/nawyegypt'
    ],
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+201029208551',
      email: 'info@nawy.com',
      contactType: 'customer service',
      areaServed: 'EG',
      availableLanguage: 'ar'
    },
    address: {
      '@type': 'PostalAddress',
      streetAddress: '47North 90 Street',
      addressLocality: 'New cairo',
      addressRegion: 'Cairo',
      postalCode: '12345',
      addressCountry: 'EG'
    },
    foundingDate: '2016',
    founder: {
      '@type': 'Person',
      name: 'Mostafa Beltagy',
      jobTitle: 'CEO'
    },
    areaServed: {
      '@type': 'AdministrativeArea',
      name: 'مصر'
    },
    brand: {
      '@type': 'Brand',
      name: 'ناوي',
      description: 'أفضل وسيط عقاري في مصر',
      logo: 'https://www.nawy.com/assets/icons/common/nawy.svg'
    },
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: 'https://www.nawy.com/ar/search?q={search_term_string}'
      },
      query: 'required name=search_term_string'
    },
    keywords:
      'المشروعات العقارية, مصر, كمبوندات مصر, وحدات للبيع, عقارات, الاستثمار العقاري'
  };

  return JsonLd<Organization>('organization-schema', {
    '@context': 'https://schema.org',
    ...(props.locale === 'ar' ? organizationAr : organizationEn)
  });
};

export default OrganizationSchema;
