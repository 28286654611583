import { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { type Swiper as SwiperRef } from 'swiper';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// Assets
import ArrowIcon from './svg-icons/ArrowIcon';
import PrevArrowIcon from './svg-icons/PrevArrowIcon';

interface SliderContainerProps {
  slidesPerView: number | 'auto';
  spaceBetweenSlides?: number;
  slidesPerGroup: number;
  children: Array<JSX.Element>;
  width?: string;
  height?: string;
  autoPlay?: boolean;
  withPagination?: boolean;
}

const Slider: React.FC<SliderContainerProps> = props => {
  SwiperCore.use([Autoplay, Pagination]);
  const { locale, asPath } = useRouter();
  let swiperRef = useRef<SwiperRef | any>(null);
  const isMobile = useSelector((state: any) => state.app.isMobile);
  const [isBeginning, setIsBeginning] = useState(locale === 'en');
  const [isEnd, setIsEnd] = useState(locale !== 'en');

  const onSlideChange = () => {
    if (locale === 'en') {
      setIsBeginning(swiperRef.current.swiper.isBeginning);
      setIsEnd(swiperRef.current.swiper.isEnd);
    } else {
      setIsBeginning(swiperRef.current.swiper.isEnd);
      setIsEnd(swiperRef.current.swiper.isBeginning);
    }
  };

  useEffect(() => {
    onSlideChange();
  }, []);

  const slideNext = () => {
    swiperRef.current.swiper.slideNext();
    onSlideChange();
  };

  const slidePrev = () => {
    swiperRef.current.swiper.slidePrev();
    onSlideChange();
  };

  useEffect(() => {
    if (asPath === '/compare') {
      onSlideChange();
    }
  }, [props.children.length]);

  return (
    <SliderContainer
      locale={locale}
      width={props.width}
      height={props.height}
      withPagination={props.withPagination}
    >
      <Swiper
        ref={swiperRef}
        slidesPerView={props.slidesPerView}
        spaceBetween={props.spaceBetweenSlides}
        slidesPerGroup={props.slidesPerGroup}
        loop={false}
        freeMode={true}
        threshold={10}
        touchRatio={2}
        dir={locale == 'ar' ? 'rtl' : 'ltr'}
        direction="horizontal"
        navigation={{
          nextEl: locale == 'en' ? '.my-arrow-next' : '.my-arrow-prev',
          prevEl: locale == 'en' ? '.my-arrow-prev' : '.my-arrow-next'
        }}
        autoplay={props.autoPlay ? { delay: 5000 } : false}
        modules={[Navigation]}
        className="mySwiper"
        onSlideChange={onSlideChange}
        onResize={onSlideChange}
        pagination={{
          clickable: !!props.withPagination,
          enabled: !!props.withPagination
        }}
      >
        {!isMobile && (
          <>
            {!isBeginning && (
              <div
                className="my-arrow-prev"
                onClick={() => (locale == 'en' ? slidePrev() : slideNext())}
              >
                <div className="my-arrow-prev_wrapper">
                  <PrevArrowIcon
                    stroke="white"
                    styles={{ width: '25px', height: '12px' }}
                  />
                </div>
              </div>
            )}
            {!isEnd && (
              <div
                className="my-arrow-next"
                onClick={() => (locale == 'en' ? slideNext() : slidePrev())}
              >
                <div className="my-arrow-next_wrapper">
                  <ArrowIcon
                    stroke="white"
                    styles={{ width: '25px', height: '12px' }}
                  />
                </div>
              </div>
            )}
          </>
        )}
        {props.children.map((child, index) => {
          return (
            <SwiperSlide
              itemProp="itemListElement"
              data-test="swiper-slide"
              key={child.key ?? index}
            >
              {child}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </SliderContainer>
  );
};

const SliderContainer = styled.div<any>`
  .swiper {
    width: 100%;
    height: 100%;
    position: relative !important;
    overflow: hidden !important;

    .swiper-wrapper {
      display: flex !important;
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .swiper-slide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    text-align: center;
    font-size: ${props => props.theme.font.sizes.xl};
    width: ${props => (props.width ? props.width : 'fit-content')};
    height: ${props => (props.height ? props.height : 'fit-content')};
  }
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .my-arrow-next {
    position: absolute !important;
    height: 100% !important;
    width: fit-content !important;
    background: linear-gradient(
      268.46deg,
      rgba(255, 255, 255, 0.63) 1.23%,
      rgba(217, 217, 217, 0) 59.07%
    );
    justify-content: flex-end;
    align-items: center;
    bottom: 0% !important;
    right: 0% !important;
    z-index: 10;
    cursor: pointer;
    display: flex;

    .my-arrow-next_wrapper {
      width: 40px;
      height: 40px;
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 4px;
    }
  }
  .my-arrow-prev {
    position: absolute !important;
    height: 100% !important;
    width: fit-content !important;
    bottom: 0% !important;
    left: 0% !important;
    z-index: 10;
    cursor: pointer;
    align-items: center !important;
    background: linear-gradient(
      92.42deg,
      rgba(255, 255, 255, 0.63) 2.03%,
      rgba(217, 217, 217, 0) 97.84%
    );
    display: flex;
  }
  .my-arrow-prev_wrapper {
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
  }
  .swiper-pagination {
    position: absolute;
    bottom: -5%;
    left: 50%;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    z-index: 99999999999999999 !important;
    transform: rotateZ(270deg) !important;
  }
  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    margin: 0;
    background: rgba(0, 0, 0, 0.16);
    margin-top: 53px;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background: ${props => props.theme.light.colors.primaryBlue};
  }
`;

export default Slider;
