import ImageWrapper from '../ImageWrapper';

type PropsType = {
  styles?: any;
  fill?: string;
};

const CloseIcon = ({ styles, fill }: PropsType) => {
  return (
    <ImageWrapper
      src={`/assets/icons/new/close-icon.svg`}
      alt={'close-icon'}
      width={styles?.width ?? 28}
      height={styles?.height ?? 28}
    />
  );
};

export default CloseIcon;
