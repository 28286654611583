import { useSearchContext } from '../context/SearchContext';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { AreasApis } from '../../../new-lib/apis/areas.apis';
import { DevelopersApis } from '../../../new-lib/apis/developers.apis';

export const useAreasFilters = () => {
  const { areas, setAreas } = useSearchContext();

  const { refetch, isFetching } = useQuery(
    ['areas'],
    () => AreasApis.listAll(),
    {
      enabled: false,
      staleTime: Infinity,
      onSuccess: fetchedAreas => setAreas(fetchedAreas)
    }
  );

  useEffect(() => {
    if (areas.length === 0) {
      refetch();
    }
  }, [areas, refetch]);

  return {
    areas,
    isFetching
  };
};

export const useDevelopersFilters = () => {
  const { developers, setDevelopers } = useSearchContext();

  const { refetch, isFetching } = useQuery(
    ['developers'],
    () => DevelopersApis.listAll(),
    {
      enabled: false,
      staleTime: Infinity,
      onSuccess: fetchedDevelopers => setDevelopers(fetchedDevelopers)
    }
  );

  useEffect(() => {
    if (developers.length === 0) {
      refetch();
    }
  }, [developers]);

  return {
    developers,
    isFetching
  };
};
