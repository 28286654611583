import { appWithTranslation } from 'next-i18next';
import { wrapper } from '../redux/store';
import { QueryClient, QueryClientProvider } from 'react-query';
import NextNProgress from 'nextjs-progressbar';
import Layout from '../new-components/layout';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { theme } from '../styles/theme';
import GlobalStyles from '../styles/global-styles';
import SearchProvider from '../features/search-new/context/SearchContext';
import { AnalyticsScripts } from '../new-lib/analytics/analytics-scripts';
import isPropValid from '@emotion/is-prop-valid';
import { useState } from 'react';
import { Provider } from 'react-redux';
import '../styles/main.css';
import { useRouter } from 'next/router';
import SchemaBuilder from '../features/structured-data/components/SchemaBuilder';

function MyApp({ Component, ...rest }: any) {
  const {
    store,
    props: { pageProps }
  } = wrapper.useWrappedStore(rest);
  const [queryClient] = useState(() => new QueryClient());
  const { locale } = useRouter();

  return (
    <ThemeProvider theme={theme}>
      <AnalyticsScripts />
      <SchemaBuilder pageProps={pageProps} locale={locale} />
      <GlobalStyles />
      <NextNProgress
        color={theme.light.colors.primaryBlue}
        height={3}
        options={{ easing: 'ease', speed: 50 }}
        showOnShallow={false}
      />
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <SearchProvider
            filterOptions={JSON.parse(pageProps.filterOptions)}
            areas={pageProps.areas}
            developers={pageProps.developers}
          >
            <StyleSheetManager shouldForwardProp={prop => isPropValid(prop)}>
              <Layout {...pageProps}>
                <Component {...pageProps} />
              </Layout>
            </StyleSheetManager>
          </SearchProvider>
        </QueryClientProvider>
      </Provider>
      {/*The container (DOM location) in which the react portal is injected */}
      <div id="portal" />
      <div>
        <meta itemProp="url" content="https://www.nawy.com/" />
      </div>
    </ThemeProvider>
  );
}

export default appWithTranslation(MyApp);
