import ImageWrapper from '../ImageWrapper';

const AppleDownloadIcon = () => {
  return (
    <ImageWrapper
      src={`/assets/icons/new/apple-download-icon.svg`}
      alt={'apple-download-icon'}
      width={101}
      height={37}
    />
  );
};

export default AppleDownloadIcon;
