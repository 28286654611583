import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import ImageWrapper from './ImageWrapper';

type BreadcrumbItem = {
  displayedName: string;
  path: string;
};

interface BreadcrumbsProps {
  itemsToShow: BreadcrumbItem[];
}

const Breadcrumbs = ({ itemsToShow }: BreadcrumbsProps) => {
  const { t } = useTranslation('common');
  const { locale } = useRouter();
  return (
    <MainContainer locale={locale} id="breadcrumbs">
      <div>
        <ImageWrapper
          src={'/assets/icons/common/home-icon.svg'}
          alt="Root Icon"
          width={16}
          height={14}
          priority
          objectFit="cover"
          imageSize="vLow"
        />
        <Link href="/" prefetch id="home-breadcrumb">
          <span>{t('home')}</span>
        </Link>
        <meta content="1" />
      </div>
      {itemsToShow.map((item, idx) => {
        return (
          <div key={idx}>
            <ImageWrapper
              src={'/assets/icons/common/breadcrumb-arrow.svg'}
              alt="Arrow Icon"
              width={6}
              height={10}
              className="arrow-icon"
              priority
              objectFit="cover"
              imageSize="vLow"
            />
            <Link
              href={item.path}
              prefetch
              id={`breadcrumb-${idx + 2}`}
            >
              <span>{t(item.displayedName)}</span>
            </Link>
            <meta content={`${idx + 2}`} />
          </div>
        );
      })}
    </MainContainer>
  );
};

const MainContainer = styled.div<{ locale?: string }>`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  overflow-x: auto;
  margin: 32px 0 8px 0;
  div {
    display: flex;
    align-items: center;

    a {
      font-family: materialRegular;
      font-size: ${props => props.theme.font.sizes.s};
      text-decoration-line: underline;
      text-transform: capitalize;
      color: ${props => props.theme.light.colors.darkGrey};
      cursor: pointer;
      white-space: nowrap;
      padding: 0px 5px;
    }

    .arrow-icon {
      transform: ${props => props.locale == 'ar' && 'rotate(180deg)'};
    }
  }

  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: ${props =>
      props.theme.breakPoints.tablet.max}) {
    margin: 8px 0 16px;
    div {
      a {
        color: ${props => props.theme.light.colors.darkGrey};
        cursor: pointer;
        padding: 0px 3px;
        font-size: ${props => props.theme.font.sizes.xxs};
      }

      .arrow-icon {
        transform: ${props => props.locale == 'ar' && 'rotate(180deg)'};
      }
    }
  }
`;
export default Breadcrumbs;
