import styled from 'styled-components';
import FilterWrapper from './FilterWrapper';
import { FilterMenuProps } from './FiltersMenuContainer';
import { useTranslation } from 'next-i18next';
import {
  SearchContext,
  SearchContextInterface
} from '../../context/SearchContext';
import { useContext } from 'react';
import { useRouter } from 'next/router';
import { resetFilters } from '../../../../helpers/search-filters';

const FiltersMenuPresentation = ({ filters }: FilterMenuProps) => {
  const { t } = useTranslation('search');
  const router = useRouter();
  const { searchFiltersDispatch } = useContext(
    SearchContext
  ) as SearchContextInterface;

  return (
    <Container className="filters-container">
      {filters.map((filter: any) => {
        return (
          <FilterWrapper
            filterName={filter.name}
            filterStyle={filter.filterStyle.desktop}
            filterOptions={filter.options}
            filterType={filter.type}
            withReset={filter.withReset}
            key={filter.name}
            prefix={filter.prefix}
            step={filter.step}
            withSeeMore={filter.withSeeMore}
            singleSelect={filter.singleSelect}
          />
        );
      })}
      <button
        className="reset-filters-btn"
        onClick={() => resetFilters(router, searchFiltersDispatch)}
      >
        {t('reset_all_filters')}
      </button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  > div {
    padding: 16px 0;
    border-bottom: 1px solid #d3dbe6;
    &#areas {
      padding-top: 0px;
    }
    &#bedrooms {
      border-bottom: none;
      padding-bottom: 0;
    }
    &#amenities {
      padding-bottom: 80px;
      border-bottom: none;
    }
    &:last-child {
      padding-bottom: 100px;
      border-bottom: none;
    }
  }

  .reset-filters-btn {
    position: absolute;
    bottom: 5px;
    align-self: center;
    display: flex;
    height: 37px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #015c9a;
    color: #eeeef0;
    text-align: center;
    font-family: materialRegular;
    font-size: 14px;
    border: 0px solid transparent;
  }
`;

export default FiltersMenuPresentation;
