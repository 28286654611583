import { Thing, WithContext } from 'schema-dts';
import Script from 'next/script';

export function JsonLd<T extends Thing>(id: string, json: WithContext<T>) {
  return (
    <>
      <Script
        id={id}
        strategy="beforeInteractive"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(json) }}
      />
    </>
  );
}
