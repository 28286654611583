import { useRouter } from 'next/router';
import { Range } from 'react-range';
import styled from 'styled-components';

interface SliderProps {
  rangeLimit: { min: number; max: number };
  handleRangeChange: (values: number[]) => void;
  applyFilter: () => void;
  range: number[];
  step?: number;
}

const Slider = ({
  range,
  rangeLimit,
  handleRangeChange,
  step,
  applyFilter
}: SliderProps) => {
  const { locale } = useRouter();
  return (
    <Container>
      <Range
        rtl={locale == 'ar'}
        min={rangeLimit.min}
        max={rangeLimit.max}
        step={step}
        values={range}
        onChange={handleRangeChange}
        onFinalChange={applyFilter}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '5px',
              width: '100%',
              background: '#E8F4FF'
            }}
            className="range-bar-bg"
          >
            {children}
          </div>
        )}
        renderThumb={({ props: { key, ...rest }, isDragged }) => (
          <div
            key={key}
            {...rest}
            style={{
              ...rest.style,
              height: '24px',
              width: '24px',
              borderRadius: '50%',
              backgroundColor: '#015C9A',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
              // boxShadow: '0px 2px 6px #d3d3d3'
            }}
          >
            <div
              style={{
                height: '10px',
                width: '10px',
                backgroundColor: '#fff',
                borderRadius: '360px'
              }}
            />
          </div>
        )}
      />
    </Container>
  );
};

const Container = styled.div`
  margin: 16px auto;
  .slider {
    position: relative;
    width: 200px;
    height: 10px;
    .slider__track,
    .slider__range {
      border-radius: 3px;
      height: 10px;
      position: absolute;
    }
    .slider__track {
      width: 100%;
      z-index: 1;
    }

    .slider__range {
      background-color: #14aaa2;
      z-index: 2;
      width: 50%;
    }
  }

  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 200px;
    outline: none;
  }

  .thumb--zindex-3 {
    z-index: 3;
  }

  .thumb--zindex-4 {
    z-index: 4;
  }
  .thumb--zindex-5 {
    z-index: 5;
  }

  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    /* background-color: #f1f5f7; */
    border: none;
    border-radius: 50%;
    /* box-shadow: 0 0 1px 1px #ced4da; */
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }

  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    /* background-color: #f1f5f7; */
    border: none;
    border-radius: 50%;
    /* box-shadow: 0 0 1px 1px #ced4da; */
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
`;

export default Slider;
