import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { useContext, useEffect, useState } from 'react';
import {
  SearchContext,
  SearchContextInterface
} from '../../../context/SearchContext';
import { SearchFiltersActionTypes } from '../../../../../reducers/search-filters/actions';
import { SearchFiltersTypes } from '../../../../../reducers/search-filters/reducer';
import { useRouter } from 'next/router';
import { updateQuery } from '../../../../../helpers/common';
import CheckboxFilterPresentation from '../select-filter/CheckboxFilterPresentation';
import { useSelector } from 'react-redux';
import ChipFilterPresentation from '../select-filter/ChipFilterPresentation';
import { SearchViewTypes } from '../../../../../new-lib/constants/common';

interface ContainerProps {
  filterOptions: any;
  filterType: {
    delivery_date: SearchFiltersTypes.DELIVERY_DATE;
    delivery_years: SearchFiltersTypes.DELIVERY_YEARS;
  };
}

const DeliveryFilter = ({ filterOptions, filterType }: ContainerProps) => {
  const { t } = useTranslation('search');
  const { searchFiltersState, searchFiltersDispatch } = useContext(
    SearchContext
  ) as SearchContextInterface;
  const router = useRouter();
  const [selectedYears, setSelectedYears] = useState<any>([]);
  const [delivered, setDelivered] = useState<any>(undefined);
  const isMobile = useSelector((state: any) => state.app.isMobile);
  const handleSelect = (option: any) => {
    if (typeof option.value === 'string') {
      let newValue = delivered == undefined ? option.value : undefined;
      searchFiltersDispatch({
        type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
        payload: {
          name: filterType.delivery_date,
          value: newValue
        }
      });
      if (router.query?.view !== SearchViewTypes.MAP) {
        newValue
          ? updateQuery(
              router,
              [{ name: filterType.delivery_date, value: option.value }],
              undefined
            )
          : updateQuery(router, [], [filterType.delivery_date]);
      }
      setDelivered(newValue);
    } else {
      let newValues = selectedYears;
      if (selectedYears.includes(option.value)) {
        newValues = selectedYears.filter(
          (value: any) => value !== option.value
        );
      } else {
        newValues = [...selectedYears, option.value];
      }
      setSelectedYears(newValues);
      searchFiltersDispatch({
        type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
        payload: {
          name: filterType.delivery_years,
          value: newValues
        }
      });

      if (newValues.length == 0 && router.query?.view !== SearchViewTypes.MAP) {
        updateQuery(router, [], [filterType.delivery_years]);
      } else {
        updateQuery(
          router,
          [{ name: filterType.delivery_years, value: newValues.toString() }],
          [filterType.delivery_years]
        );
      }
    }
  };

  useEffect(() => {
    if (searchFiltersState.searchFilters[filterType.delivery_date]) {
      setDelivered(searchFiltersState.searchFilters[filterType.delivery_date]);
    } else if (
      searchFiltersState.searchFilters[filterType.delivery_date] === undefined
    ) {
      setDelivered(undefined);
    }

    if (searchFiltersState.searchFilters[filterType.delivery_years]) {
      setSelectedYears(
        searchFiltersState.searchFilters[filterType.delivery_years]
      );
    } else {
      setSelectedYears([]);
    }
  }, [searchFiltersState]);

  return isMobile || !router.asPath.includes('/search') ? (
    <ChipFilterPresentation
      filterOptions={filterOptions}
      handleSelect={handleSelect}
      selectedValues={[delivered && delivered, ...selectedYears]}
    />
  ) : (
    <Container>
      <div className="inputs-container">
        <CheckboxFilterPresentation
          filterName="delivery_date"
          filterOptions={filterOptions}
          handleSelect={handleSelect}
          selectedValues={[delivered && delivered, ...selectedYears]}
          isNawyNowSelected={searchFiltersState.searchFilters.nawy_now}
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  .inputs-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-bottom: 8px;
  }
  .filter-title {
    text-transform: capitalize;
    font-size: 14px;
    color: #1a2028;
    line-height: 150%;
    font-family: materialMedium;
    display: block;
    margin-bottom: 8px;

    @media screen and (max-width: ${props =>
        props.theme.breakPoints.mobile.max}) {
      font-size: 17px;
      font-family: materialBold;
    }
  }
`;

export default DeliveryFilter;
