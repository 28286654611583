export enum COOKIE_KEYS {
  NEXT_LOCALE = 'NEXT_LOCALE',
}

export class CookiesUtils {
  public static addCookie = ({ key, value, expiry }: { key: COOKIE_KEYS; value: string; expiry?: string }) => {
    const domain = window.location.hostname;
    document.cookie = `${key}=${value};domain=${domain};path=/` + expiry ? `;Expires=${expiry}` : '';
  };

  public static getCookie = (key: COOKIE_KEYS) => {
    return document.cookie
      ?.split('; ')
      ?.find((row) => row.startsWith(`${key}=`))
      ?.split('=')[1];
  };
}
