import dynamic from 'next/dynamic';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Breadcrumbs from '../../../new-components/Breadcrumbs';
import { trackEvent } from '../../../new-lib/remarketing';
import { FAQ } from '../../../new-lib/types/common';
import { SearchFiltersTypes } from '../../../reducers/search-filters/reducer';
import { SearchFiltersActionTypes } from '../../../reducers/search-filters/actions';
import {
  SearchContext,
  SearchContextInterface
} from '../../search-new/context/SearchContext';
const MobileCompoundView = dynamic(() => import('./MobileCompoundView'));
const CompoundView = dynamic(() => import('./CompoundView'));
interface CompoundProps {
  compound: any;
  availablePropertyTypes: any;
  hasAvailability: boolean;
  filterOptions: any;
  launches: any;
  showOffers: boolean;
  recommendedCompounds: any;
  faqs: FAQ[];
  propertyTypeId?: any;
}
export const getCompoundBreadcrumbsItems = (compound: any) => {
  return [
    {
      displayedName: compound.area.name,
      path: `/area/${compound.area.slug}`
    },
    {
      displayedName: compound.name,
      path: `/compound/${compound.slug}`
    }
  ];
};
const getCompoundBreadcrumbs = (compound: any) => {
  return <Breadcrumbs itemsToShow={getCompoundBreadcrumbsItems(compound)} />;
};
const Compound = (props: CompoundProps) => {
  const isMobile = useSelector((state: any) => state.app.isMobile);
  const { searchFiltersDispatch } = useContext(
    SearchContext
  ) as SearchContextInterface;

  useEffect(() => {
    if (props.propertyTypeId) {
      searchFiltersDispatch({
        type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
        payload: {
          name: SearchFiltersTypes.PROPERTY_TYPES,
          value: [props.propertyTypeId]
        }
      });
    }
  }, [props.propertyTypeId]);

  useEffect(() => {
    trackEvent.viewContent({
      listingIds: [props.compound.id],
      listingPrice: props.compound.min_price
    });
  }, []);
  return isMobile ? (
    <MobileCompoundView
      {...props}
      breadcrumbs={getCompoundBreadcrumbs(props.compound)}
      recommendedCompounds={props.recommendedCompounds}
    />
  ) : (
    <CompoundView
      {...props}
      breadcrumbs={getCompoundBreadcrumbs(props.compound)}
      recommendedCompounds={props.recommendedCompounds}
    />
  );
};
export default Compound;
