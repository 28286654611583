import styled from 'styled-components';
import { logEvent } from '../../new-lib/analytics';
import AppleDownloadIcon from '../svg-icons/AppleDownloadIcon';
import GoogleDownloadIcon from '../svg-icons/GoogleDownloadIcon';

interface ChildProps {
  iconWidth: string;
  iconColor: string;
  iconHeight?: string;
  eventLocation: string;
}

export default function DownloadApp({
  iconWidth,
  iconColor,
  iconHeight,
  eventLocation
}: ChildProps) {
  return (
    <Container
      iconColor={iconColor}
      iconWidth={iconWidth}
      iconHeight={iconHeight}
    >
      <div className="download-btns">
        <span
          onClick={event => {
            event.stopPropagation(); // Prevents parent <a> from triggering
            window.open(
              'https://apps.apple.com/eg/app/nawy-real-estate/id1571474485',
              '_blank',
              'noopener,noreferrer'
            );
            logEvent.click('download_app', eventLocation, 'other', {
              download_platform: 'ios'
            });
          }}
        >
          <AppleDownloadIcon />
        </span>
        <span
          onClick={event => {
            event.stopPropagation(); // Prevents parent <a> from triggering
            window.open(
              'https://play.google.com/store/apps/details?id=com.cooingestate.cooing_app&hl=en&gl=US',
              '_blank',
              'noopener,noreferrer'
            );
            logEvent.click('download_app', eventLocation, 'other', {
              download_platform: 'android'
            });
          }}
        >
          <GoogleDownloadIcon />
        </span>
      </div>
    </Container>
  );
}

const Container = styled.div<{
  iconWidth: string;
  iconColor: string;
  iconHeight?: string;
}>`
  display: flex;
  flex-direction: column;

  .download-btns {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: center;

    a {
      width: ${props => props.iconWidth};
      height: 100%;
      height: ${props => props.iconHeight};

      svg {
        width: ${props => props.iconWidth};
        height: 100%;
        path {
          fill: ${props => props.iconColor};
        }
      }
    }
  }
`;
