import Script from 'next/script';
import { TiktokPixelScript } from '../TiktokPixel';
import { MoengageScript } from '../MoengageScript';
import { useRouter } from 'next/router';
import { AnalyticsService } from './analytics-service';
import { HotjarScript } from '../HotjarScript';

export const AnalyticsScripts = () => {
  const router = useRouter();
  const { asPath } = router;

  return (
    <>
      <Script
        onLoad={() => window.dispatchEvent(new CustomEvent('gtagLoaded'))}
        id="gtag"
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${AnalyticsService.GA4_ID}`}
      />
      <Script id="gtag-script" strategy="lazyOnload">
        {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${AnalyticsService.GA4_ID}');
          gtag('config', 'AW-877812802');
        `}
      </Script>

      <Script
        strategy="lazyOnload"
        id="facebook-pixel"
        onLoad={() => window.dispatchEvent(new CustomEvent('fbqLoaded'))}
      >
        {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${
              asPath.includes('/nawy-partners')
                ? AnalyticsService.PARTNERS_FB_ID
                : AnalyticsService.LISTING_FB_ID
            });
            fbq('track', 'PageView');
          `}
      </Script>

      <TiktokPixelScript />

      {/*<MoengageScript />*/}

      {process.env.NEXT_PUBLIC_HOST?.includes('nawy.com') && <HotjarScript />}
    </>
  );
};
