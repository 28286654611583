import styled from 'styled-components';
import ChipPresentation from './ChipPresentation';
import { SearchFiltersTypes } from '../../../../../reducers/search-filters/reducer';

interface ContainerProps {
  filterOptions: any;
  selectedValues: any;
  handleSelect: any;
  singleSelect?: boolean;
  isNawyNowSelected?: boolean;
}

const ChipFilterPresentation = ({
  filterOptions,
  selectedValues,
  handleSelect,
  singleSelect,
  isNawyNowSelected
}: ContainerProps) => {
  return (
    <Container>
      {filterOptions.map((option: any) => {
        return (
          <ChipPresentation
            selectedValues={selectedValues}
            handleSelect={handleSelect}
            option={option}
            key={option.value}
            singleSelect={singleSelect}
            isNawyNowSelected={isNawyNowSelected}
          />
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

export default ChipFilterPresentation;
