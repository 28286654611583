import { UserLocation } from '../../new-lib/types/common';

export enum WEBVIEW {
  APP = 'app',
  WEB = 'web',
  PARTNERS = 'partners'
}

export const showOpenAppBanner = () => (dispatch: any) => {
  dispatch({
    type: 'show_open_app'
  });
};

export const hideOpenAppBanner = () => (dispatch: any) => {
  dispatch({
    type: 'hide_open_app'
  });
};

export const setReferer = (referer?: string) => (dispatch: any) => {
  dispatch({
    type: 'set_referer',
    payload: referer
  });
};

export const setUser = (userId: string) => (dispatch: any) => {
  dispatch({
    type: 'set_user',
    payload: userId
  });
};

export const setEvent = (eventId: string) => (dispatch: any) => {
  dispatch({
    type: 'set_event',
    payload: eventId
  });
};

export const setIsMobile = (isMobile: boolean) => (dispatch: any) => {
  dispatch({
    type: 'set_is_mobile',
    payload: isMobile
  });
};

export const setExperimentVariant =
  (experimentVariant: string | null) => (dispatch: any) => {
    dispatch({
      type: 'set_experiment',
      payload: experimentVariant
    });
  };

export const setWebview = (webview: WEBVIEW | null) => (dispatch: any) => {
  dispatch({
    type: 'set_webview',
    payload: webview
  });
};

export const setUserLocation =
  (userLocation: UserLocation) => (dispatch: any) => {
    dispatch({
      type: 'set_user_location',
      payload: userLocation
    });
  };

export const setTranslatedPath =
  (translatedPath: string) => (dispatch: any) => {
    dispatch({
      type: 'set_translated_path',
      payload: translatedPath
    });
  };
