import styled from 'styled-components';
import { ReactNode, MouseEvent } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

interface ButtonProps {
  text: string;
  subText?: string;
  backgroundColor: string;
  backgroundHover?: string;
  color: string;
  containerClass?: string;
  btnClass?: string;
  hoverColor?: string;
  fontSize: string;
  padding: string;
  border?: string;
  icon: ReactNode;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  dataTest?: string;
  displayNew?: boolean;
}

interface ContainerProps {
  backgroundColor: string;
  backgroundHover?: string;
  color: string;
  hoverColor?: string;
  fontSize: string;
  padding: string;
  border?: string;
  locale?: string;
}

const ButtonTextIcon = ({
  text,
  subText,
  backgroundColor,
  backgroundHover,
  color,
  hoverColor,
  fontSize,
  padding,
  border,
  icon,
  btnClass,
  containerClass,
  onClick,
  dataTest,
  displayNew
}: ButtonProps) => {
  const { locale } = useRouter();
  const { t } = useTranslation('entity');

  return (
    <Container
      backgroundColor={backgroundColor}
      backgroundHover={backgroundHover}
      color={color}
      hoverColor={hoverColor}
      fontSize={fontSize}
      padding={padding}
      border={border}
      className={containerClass}
      data-test={dataTest}
      locale={locale}
    >
      <button
        onClick={(e: any) => onClick(e)}
        className={`${btnClass} ${displayNew ? 'map-text' : ''}`}
      >
        {icon}
        <div className="btn-text">
          {subText && <span className="sub-text">{subText}</span>}
          <span>{text}</span>
        </div>
      </button>
    </Container>
  );
};

const Container = styled.div<ContainerProps>`
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: materialRegular;
    border-radius: ${props => props.theme.borderRadius};
    height: 35px;
    border: ${props => props.border};
    background-color: ${props => props.backgroundColor};
    font-size: ${props => props.fontSize};
    padding: ${props => props.padding};
    color: ${props => props.color};
    transition: all 0.2s ease-out;
    cursor: pointer;

    &.map-text {
      position: relative;
      &::after {
        content: ${props => (props.locale === 'ar' ? '"جديد"' : '"New"')};
        position: absolute;
        top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        right: ${props => (props.locale == 'ar' ? '4px' : 'unset')};
        left: ${props => (props.locale == 'en' ? '4px' : 'unset')};
        color: ${props => props.theme.light.colors.white};
        background-color: ${props => props.theme.light.colors.primaryOrange};
        width: 26px;
        height: 12px;
        border-radius: 100px;
        color: #fff;
        text-align: center;
        font-family: materialRegular;
        font-size: 8px;
        text-transform: capitalize;
      }
    }

    .btn-text {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      .sub-text {
        font-size: 8px;
      }
    }
    svg {
      path {
        min-width: 27px;
        height: auto;
        transition: all 0.2s ease-out;
        fill: ${props => props.color};
      }
    }
    span {
      margin: 0px 5px;
    }

    @media screen and (min-width: ${props =>
        props.theme.breakPoints.desktop.min}) {
      :hover {
        svg {
          path {
            fill: ${props =>
              props.hoverColor == 'none' ? '' : props.hoverColor};
          }
        }
      }
    }
  }
`;
export default ButtonTextIcon;
