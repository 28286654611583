import React from 'react';
import { BreadcrumbList } from 'schema-dts';
import { JsonLd } from './JsonLD';
import { getAreaBreadcrumbsItems } from '../../entity/area';
import { getCompoundBreadcrumbsItems } from '../../entity/compound';
import { getDeveloperBreadcrumbsItems } from '../../entity/developer';

function BreadCrumbSchema(props: { pageProps: any; locale?: string }) {
  let breadCrumbList: BreadcrumbList = {
    '@type': 'BreadcrumbList'
  };
  if (!props.pageProps) return <></>;
  let items = undefined;
  if (props.pageProps.area) {
    items = getAreaBreadcrumbsItems(
      props.pageProps.area,
      props.locale as string
    );
  } else if (props.pageProps.compound) {
    items = getCompoundBreadcrumbsItems(props.pageProps.compound);
  } else if (props.pageProps.developer) {
    items = getDeveloperBreadcrumbsItems(props.pageProps.developer);
  }
  if (
    (props.pageProps.area ||
      props.pageProps.compound ||
      props.pageProps.developer) &&
    items !== undefined
  ) {
    items.unshift({
      displayedName: props.locale === 'ar' ? 'الرئيسية' : 'Home',
      path: ''
    });
    breadCrumbList.itemListElement = items.map((item, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: item.displayedName,
      item:
        process.env.NEXT_PUBLIC_HOST +
        (props.locale === 'ar' ? '/ar' : '') +
        item.path
    }));
    return JsonLd<BreadcrumbList>('breadcrumbs-schema', {
      '@context': 'https://schema.org',
      ...breadCrumbList
    });
  } else return <></>;
}

export default BreadCrumbSchema;
