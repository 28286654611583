/* eslint-disable react-hooks/exhaustive-deps */
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { updateQuery } from '../../../../../helpers/common';
import {
  filterEventMap,
  handleSelectOptionsWithMaxValue
} from '../../../../../helpers/search-filters';
import { InputOption } from '../../../../../new-lib/types/search-filters';
import {
  FilterMultiSelectTypes,
  SearchFiltersActionTypes
} from '../../../../../reducers/search-filters/actions';
import MultiSelectPresentation from './MultiSelectPresentation';
import { logEvent } from '../../../../../new-lib/analytics';
import { SearchFiltersTypes } from '../../../../../reducers/search-filters/reducer';
import {
  SearchContext,
  SearchContextInterface
} from '../../../../search-new/context/SearchContext';
import { useSelector } from 'react-redux';
import { SearchViewTypes } from '../../../../../new-lib/constants/common';
interface MultiSelectContainerPropsType {
  filterType: FilterMultiSelectTypes;
  shapeClass: string;
  maxDisplayedValue?: number;
  isMoveNowFiltered?: boolean;
}
const MultiSelectContainer = ({
  filterType,
  maxDisplayedValue,
  shapeClass,
  isMoveNowFiltered
}: MultiSelectContainerPropsType) => {
  const { filterOptions, searchFiltersState, searchFiltersDispatch } =
    useContext(SearchContext) as SearchContextInterface;
  const [newOptions, setNewOptions] = useState<InputOption[]>([]);
  const router = useRouter();
  const searchView = useSelector((state: any) => state.map.searchView);

  useEffect(() => {
    handleSelectOptionsWithMaxValue(
      filterOptions[
        filterType === SearchFiltersTypes.DELIVERY_YEARS
          ? 'delivery_date'
          : filterType
      ],
      maxDisplayedValue,
      setNewOptions
    );
  }, []);

  const onChange = (values: string[] | number[]) => {
    searchFiltersDispatch({
      type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
      payload: {
        name: filterType,
        value: values
      }
    });

    logEvent.search(
      `filter_${filterEventMap(filterType)}`,
      'filtersMenu',
      'filter',
      searchFiltersState,
      { [filterType]: values }
    );

    if (router.query?.view !== SearchViewTypes.MAP) {
      updateQuery(
        router,
        [
          { name: filterType, value: values.toString() },
          { name: 'page_number', value: 1 }
        ],
        undefined
      );
    }

    if (values.length == 0 && router.query?.view !== SearchViewTypes.MAP) {
      updateQuery(
        router,
        [
          ...(!router.asPath.includes('/nawy-now')
            ? [{ name: 'page_number', value: 1 }]
            : [])
        ],
        [filterType]
      );
      updateQuery(router, [], [filterType]);
    }
  };
  return (
    <MultiSelectPresentation
      onChange={onChange}
      selectedValues={
        searchFiltersState.searchFilters[filterType] as Array<number | string>
      }
      options={newOptions}
      shapeClass={shapeClass}
    />
  );
};

export default MultiSelectContainer;
