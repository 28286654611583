import React, { useRef } from 'react';
import styled from 'styled-components';
import LocationFilterTabs from './LocationFilterTabs';
import LocationSearchBar from './LocationSearchBar';
import { useLocationFilter } from '../../../hooks/LocationFilter';
import LocationSearchDropdown from './LocationSearchDropdown';
import useOnClickOutside from '../../../../../hooks/ClickOutside';

interface filterProps {}

const LocationSearch = ({}: filterProps): JSX.Element => {
  const {
    handleRemove,
    filtersContainer,
    changeSelectedTab,
    setSearchTerm,
    handleSelect,
    searchResults,
    clearAll,
    setDropDownOpen,
    dropDownOpen
  } = useLocationFilter();
  const searchBarRef = useRef(null);
  const dropdownRef = useRef(null);
  useOnClickOutside(searchBarRef, dropdownRef, () => {
    setDropDownOpen(false);
  });

  return (
    <Container>
      <LocationFilterTabs
        selectedTab={filtersContainer.selectedType}
        setSelectedTab={changeSelectedTab}
      />
      <LocationSearchBar
        selectedSearches={filtersContainer[filtersContainer.selectedType]}
        dropdownOpen={dropDownOpen}
        handleRemove={handleRemove}
        setDropdownOpen={setDropDownOpen}
        setSearchTerm={setSearchTerm}
        searchBarRef={searchBarRef}
      />
      {dropDownOpen && (
        <LocationSearchDropdown
          selectedSearches={filtersContainer[filtersContainer.selectedType]}
          handleRemove={handleRemove}
          handleSelect={handleSelect}
          searchResults={searchResults}
          clearAll={clearAll}
          dropdownRef={dropdownRef}
          filterType={filtersContainer.selectedType}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
`;

export default LocationSearch;
