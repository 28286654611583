import { LanguageUtils } from './language-utils';
import { isOnlyWhitespace } from '../../helpers/common';

export class SeoUtils {
  //// Homepage
  public static getHomepageMetaTitle = (locale: string = 'en'): string => {
    if (LanguageUtils.isArabic(locale)) {
      return 'Nawy | ناوي وجهتك الأولى للعقارات في مصر - اكتشف آلاف الوحدات';
    }
    return 'Nawy Estate | Premium Real Estate in Egypt';
  };

  public static getHomepageMetaDescription = (
    locale: string = 'en'
  ): string => {
    if (LanguageUtils.isArabic(locale)) {
      return 'ناوي أفضل وسيط عقاري في مصر. ابحث وقارن بين مجموعة من الوحدات للبيع داخل كمبوندات القاهرة الجديدة وأكتوبر والعاصمة الإدارية والساحل الشمالي وغيرها واحجز وحدتك الآن Nawy';
    }
    return 'Nawy is the best real estate broker in Egypt. Browse areas, projects, and properties for sale in New Cairo, October, North Coast - Sahel, etc. Filter the search by prices & area to find a home';
  };

  //// Search
  public static getSearchMetaTitle = ({
    locale = 'en',
    title,
    isMapView
  }: {
    locale?: string;
    title?: string;
    isMapView?: boolean;
  }): string => {
    if (!isMapView && title) {
      return title;
    }

    const isArabic = LanguageUtils.isArabic(locale);

    if (isMapView) {
      return isArabic
        ? 'خريطة كمبوندات مصر: اكتشف آلاف الوحدات للبيع في أنحاء مصر - Nawy'
        : 'Egypt Compounds Map: Discover All Projects​ in Egypt - Nawy';
    } else {
      return isArabic
        ? 'دليل أفضل كمبوندات في مصر - وحدات للبيع بأفضل الأسعار - Nawy'
        : 'Best Compounds in Egypt: Your Guide to Luxury Properties - Nawy';
    }
  };

  public static getSearchMetaDescription = ({
    locale = 'en',
    description,
    isMapView
  }: {
    locale?: string;
    description?: string;
    isMapView?: boolean;
  }): string => {
    if (!isMapView && description) {
      return description;
    }

    const isArabic = LanguageUtils.isArabic(locale);

    if (isMapView) {
      return isArabic
        ? 'استكشف خريطة كمبوندات مصر التي تشمل خريطة العاصمة الإدارية وخريطة الساحل الشمالي ومناطق أخرى، وتصفح جميع الوحدات المتاحة للبيع في أهم المشروعات العقارية، واختر وحدة أحلامك بالسعر المناسب لك'
        : 'Nawy Egypt Compounds map is your guide to all real estate projects in Egypt. Learn everything you need to know about the compounds in Cairo, Sahel, Sokhna, Gouna & more.';
    } else {
      return isArabic
        ? 'ابحث عن منزل أحلامك في دليل كمبوندات مصر من Nawy وتصفح آلاف الوحدات المتاحة للبيع بأفضل الأسعار، وقارن بين كمبوندات القاهرة الجديدة ومدينة السادس من أكتوبر والعاصمة الإدارية الجديدة وغيرها.'
        : 'Check the top compounds in Egypt, their properties for sale, prices, amenities & photos. Nawy offers an array of compounds in New Cairo, New Capital City, 6th of October City, North Coast, etc.';
    }
  };

  //// Area
  public static getAreaMetaTitle = (
    area: any,
    locale: string = 'en'
  ): string => {
    const showFirst = area.id % 2 === 0;
    const showSecond = area.id % 2 !== 0;
    if (LanguageUtils.isArabic(locale)) {
      if (area.meta_title?.ar && area.meta_title.ar !== '') {
        return area.meta_title.ar;
      }
    } else if (area.meta_title?.en && area.meta_title.en !== '') {
      return area.meta_title.en;
    }

    if (area?.name) {
      const name = area.name;
      if (LanguageUtils.isArabic(locale)) {
        if (showFirst) {
          return `شقق في كمبوندات ${name} بأفضل سعر`;
        }
        if (showSecond) {
          return `اسعار عقارات للبيع في مشروعات ${name}`;
        }
        return `أفضل مشروعات في (${name})`;
      }
      if (showFirst) {
        return `Nawy has compounds in ${name} with numerous luxurious properties for sale.`;
      }
      return `Search the top compounds in ${name} and find the best properties and prices!`;
    }

    return LanguageUtils.isArabic(locale)
      ? `بحث عن عقارات وكمبوندات فاخرة!`
      : `Search for luxurious properties and compounds!`;
  };

  public static getAreaMetaDescription = (
    area: any,
    locale: string = 'en'
  ): string => {
    const showFirst = area.id % 2 === 0;
    if (LanguageUtils.isArabic(locale)) {
      if (area.meta_description?.ar && area.meta_description.ar !== '') {
        return area.meta_description.ar;
      }
    } else if (area.meta_description?.en && area.meta_description.en !== '') {
      return area.meta_description.en;
    }

    if (area?.name) {
      const name = area.name;
      if ((area.properties_count ?? 0) > 0) {
        if (LanguageUtils.isArabic(locale)) {
          return showFirst
            ? `عقارات للبيع في ${name} شاهد آلاف العقارات بالصور والأسعار وطريقة التقسيط وجميع التفاصيل من شقق استلام فوري بالتقسيط في ${name} وفلل للبيع في ${name}في أفضل الكمبوندات في  ${name}.`
            : `تصفح عقارات للبيع في ${name} واختار من بين مجموعة عقارات للبيع في كمبوندات ${name} من أكبر الشركات العقارية في مصر اختار شقق للبيع متشطبة بالتقسيط المريح في  ${name} وشقق للبيع في ${name} بالتقسيط استلام فوري`;
        }
        return showFirst
          ? `Discover the top compounds in ${name} and learn about their location, prices, and properties for sale.`
          : `${name} - Top compounds in ${name} - Apartments for sale in ${name} - Properties for sale in ${name} - Villas for sale in ${name} - ${name} Compounds - ${name} Egypt.`;
      } else {
        if (LanguageUtils.isArabic(locale)) {
          return `تعرف الآن على أسعار الوحدات والمرافق المتاحة وخرائط الوحدات الجديدة واعادة البيع في ${name}.`;
        }
        return `See prices, amenities, and maps of new and resale homes in ${name}.`;
      }
    }

    return LanguageUtils.isArabic(locale)
      ? `See prices, amenities, and maps of new and resale homes.`
      : `تعرف الآن على أسعار الوحدات والمرافق المتاحة وخرائط الوحدات الجديدة واعادة البيع.`;
  };

  //// Developer
  public static getDeveloperMetaTitle = (
    developer: any,
    locale: string = 'en'
  ) => {
    if (LanguageUtils.isArabic(locale)) {
      if (developer.meta_title?.ar && developer.meta_title.ar !== '') {
        return developer.meta_title.ar;
      }
    } else if (developer.meta_title?.en && developer.meta_title.en !== '') {
      return developer.meta_title.en;
    }
    return developer.name;
  };

  public static getDeveloperMetaDescription = (
    developer: any,
    locale: string = 'en'
  ) => {
    if (!developer) return '';

    if (LanguageUtils.isArabic(locale)) {
      if (
        developer.meta_description?.ar &&
        developer.meta_description.ar !== ''
      ) {
        return developer.meta_description.ar;
      }
    } else if (
      developer.meta_description?.en &&
      developer.meta_description.en !== ''
    ) {
      return developer.meta_description.en;
    }

    const { description, no_of_properties, name } = developer;

    if (description) {
      return description;
    }

    if ((no_of_properties ?? 0) > 0) {
      if (LanguageUtils.isArabic(locale)) {
        return `تملك Nawy ${no_of_properties} من الوحدات المتاحة للبيع من قبل ${name}`;
      }
      return `Nawy has over ${no_of_properties} properties available for sale by ${name}.`;
    }

    if (LanguageUtils.isArabic(locale)) {
      return `تعرف الآن على أسعار الوحدات والمرافق المتاحة وخرائط الوحدات الجديدة واعادة البيع عن طريق ${name}`;
    }
    return `See prices, amenities, and maps of new and resale homes by ${name}.`;
  };

  //// Compound
  public static getCompoundLocalizedFAQs = (
    faqs: any[],
    locale: string = 'en'
  ) => {
    if (!faqs || faqs.length === 0) {
      return [];
    }

    return faqs
      .filter((faq: any) => faq.question?.[locale] && faq.answer?.[locale])
      .map((faq: any) => ({
        question: faq.question[locale],
        answer: faq.answer[locale]
      }));
  };

  public static getCompoundMetaTitle = (
    compound: any,
    locale: string = 'en'
  ) => {
    const showFirst = compound.id % 2 === 0;
    if (LanguageUtils.isArabic(locale)) {
      if (
        compound?.meta_title?.ar &&
        !isOnlyWhitespace(compound.meta_title?.ar)
      ) {
        return compound.meta_title.ar;
      }
    } else if (
      compound?.meta_title?.en &&
      !isOnlyWhitespace(compound.meta_title?.en)
    ) {
      return compound.meta_title.en;
    }

    const name = compound?.name || compound?.title;
    const developerName = compound?.developer?.name;
    const area = compound?.area?.parent_area
      ? compound?.area?.parent_area?.name
      : compound?.area?.name;

    if (compound.id === 927) {
      if (LanguageUtils.isArabic(locale)) {
        return `عقارات للبيع في سولت الساحل الشمالي - راس الحكمة`;
      }
      return `Find the top properties for sale in ${name} by ${developerName} in ${area} - ${compound?.area?.name}.`;
    }

    if (!name || !area) {
      if (LanguageUtils.isArabic(locale)) {
        return 'ابحث عن أفضل العقارات والأسعار!';
      }
      return 'Find the best properties and prices!';
    }

    if (LanguageUtils.isArabic(locale)) {
      return showFirst
        ? `شقق للبيع في كمبوند ${name}`
        : `عقارات للبيع في ${name} ${area}`;
    }
    return showFirst
      ? `${name} by ${developerName} in ${area}`
      : `Find the top properties for sale in ${name} by ${developerName} in ${area}.`;
  };

  public static getCompoundMetaDescription = (
    compound: any,
    locale: string = 'en'
  ) => {
    if (LanguageUtils.isArabic(locale)) {
      if (
        compound?.meta_description?.ar &&
        !isOnlyWhitespace(compound.meta_description?.ar)
      ) {
        return compound.meta_description.ar;
      }
    } else if (
      compound?.meta_description?.en &&
      !isOnlyWhitespace(compound.meta_description?.en)
    ) {
      return compound.meta_description.en;
    }

    const name = compound?.name || compound?.title;
    const developerName = compound?.developer?.name;
    const area = compound?.area?.parent_area
      ? compound?.area?.parent_area?.name
      : compound?.area?.name;

    if (!name || !area) {
      if (LanguageUtils.isArabic(locale)) {
        return `تعرف الآن على أسعار الوحدات والمرافق المتاحة وخرائط الوحدات الجديدة واعادة البيع.`;
      }
      return `See prices, amenities, and maps of new and resale homes.`;
    }

    if (LanguageUtils.isArabic(locale)) {
      return `تعرف الآن على أسعار الوحدات والمرافق المتاحة وخرائط الوحدات الجديدة واعادة البيع في ${name} وذلك عن طريق ${developerName} في ${area}.`;
    }
    return `See prices, amenities, and maps of new and resale homes in ${name} by ${developerName} in ${area}.`;
  };
}
