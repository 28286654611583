import { newApi } from './api.instance';

export class ConfigsApis {
  private static END_POINT = `v1/configs`;

  public static getConfigs = async (language?: string) => {
    return newApi({ ssrLocale: language })
      .get(this.END_POINT)
      .then(({ data }) => data);
  };

  public static checkIfEuCountry = async (): Promise<boolean> => {
    return newApi()
      .get('v1/location/check-country-code')
      .then(({ data }): boolean => data?.isEuCountry ?? false)
      .catch(() => false);
  };
}
