import { useEffect } from 'react';

const OnOutsideClick = (containerRef, isListening, onOutsideClick) => {
  useEffect(() => {
    const handleMouse = e => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        onOutsideClick();
      }
    };
    if (isListening) {
      document.addEventListener('mousedown', handleMouse);
    }
    return () => {
      document.removeEventListener('mousedown', handleMouse);
    };
  }, [containerRef, isListening, onOutsideClick]);
};

export default OnOutsideClick;
