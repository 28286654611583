import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useSearch } from '../../hooks/SearchInput';
import styled from 'styled-components';
import SearchInput from './SearchInput';
import { useRouter } from 'next/router';
import SearchDropdown from './SearchDropdown';
import { useSelector } from 'react-redux';
import useOnClickOutside from '../../../../hooks/ClickOutside';
import ButtonIcon from '../../../../new-components/ui/ButtonIcon';
import ThinArrowIcon from '../../../../new-components/svg-icons/ThinArrowIcon';
import SearchFilterBtn from './SearchFilterBtn';
import { useCleanPath } from '../../../../hooks/paths.hooks';

interface SearchBarProps {
  selectedFilters?: string;
  setStackDropdown?: Dispatch<SetStateAction<boolean>>;
}

const SearchBar = ({ selectedFilters, setStackDropdown }: SearchBarProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const isMobile = useSelector((state: any) => state.app.isMobile);
  const router = useRouter();
  const path = useCleanPath();
  const { locale } = router;

  useEffect(() => {
    if (setStackDropdown) {
      setStackDropdown(dropdownOpen);
    }
  }, [dropdownOpen]);

  const {
    setSearchTerm,
    searchResults,
    isError,
    isLoading,
    selectedSearches,
    handleSelect,
    appliedFilters,
    handleRemove,
    setShowUniversalSearch,
    setShowFilters
  } = useSearch({
    setDropdownOpen,
    searchInputLocation: 'UniversalSearch',
    selectedFilters
  });
  const dropdownRef = useRef(null);

  const onClose = useCallback(() => {
    if (path === '/') {
      setShowUniversalSearch(false);
    }
    setDropdownOpen(false);
  }, [path]);

  useOnClickOutside(dropdownRef, dropdownRef, () => {
    if (!isMobile && dropdownOpen) {
      onClose();
    }
  });

  return (
    <Container ref={dropdownRef} className={isMobile ? 'container-fluid' : ''}>
      <div className="search-filter-bar">
        {dropdownOpen && isMobile && (
          <ButtonIcon
            icon={
              <ThinArrowIcon
                stroke={'#5F606A'}
                styles={locale == 'ar' ? { transform: 'rotate(180deg)' } : {}}
              />
            }
            border="none"
            backgroundColor="transparent"
            color="none"
            onClick={onClose}
            containerClass="back-button"
          />
        )}
        <SearchInput
          dropdownOpen={dropdownOpen}
          setDropdownOpen={setDropdownOpen}
          setSearchTerm={setSearchTerm}
          selectedSearches={selectedSearches}
          handleRemove={handleRemove}
        />
        {isMobile &&
          router.asPath !== '/' &&
          !router.asPath.includes('/property') && (
            <SearchFilterBtn
              setShowFilters={setShowFilters}
              appliedFilters={appliedFilters}
            />
          )}
      </div>

      {dropdownOpen && (
        <SearchDropdown
          handleSelect={handleSelect}
          searchResults={searchResults}
          isError={isError}
          isFetching={isLoading}
          dropdownRef={dropdownRef}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: ${props => props.theme.light.colors.white};
  border-radius: 10px;
  position: relative;
  height: 100%;
  margin: 0;
  .search-filter-bar {
    display: flex;
    flex-direction: row;
    gap: 8px;
    height: 100%;
    align-items: center;
  }

  @media screen and (max-width: ${props =>
      props.theme.breakPoints.mobile.max}) {
    border-radius: 0;
    .search-filter-bar {
      padding: 16px 0;

      .back-button {
        border-radius: 50%;
        background-color: #f7f9fd;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          svg {
            width: 8px;
          }
        }
      }
    }
  }
`;

export default SearchBar;
