import styled from 'styled-components';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import FooterDesktop from './Footer';
import FooterMobile from './FooterMobile';
import ToggleArrowIcon from '../../new-components/svg-icons/ToggleIcon';
import { logEvent } from '../../new-lib/analytics';
import Link from 'next/link';
import {
  FooterLinkEntity,
  FooterLinksDTO,
  PropertiesFooterLinkEntity
} from '../../new-lib/dtos';

interface FooterProps {
  footerLinks: FooterLinksDTO;
  forceMobile?: boolean;
}

const FooterContainer = ({ footerLinks, forceMobile }: FooterProps) => {
  const { t } = useTranslation('common');

  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const handleOptionClick = (index: number) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  const mappedFooterTitles = new Map<
    string,
    { name: string; link?: string; clickEventAction: string }
  >();
  mappedFooterTitles.set('areas', {
    name: 'areas',
    link: '/area',
    clickEventAction: 'footer_area'
  });
  mappedFooterTitles.set('developers', {
    name: 'developers',
    link: '/developer',
    clickEventAction: 'footer_developer'
  });
  mappedFooterTitles.set('seoBacklinks', {
    name: 'others',
    link: '/buy',
    clickEventAction: 'footer_backlinks'
  });
  mappedFooterTitles.set('properties', {
    name: 'top_searches',
    link: '/search',
    clickEventAction: 'footer_top_searches'
  });
  mappedFooterTitles.set('compounds', {
    name: 'compounds',
    link: '/compound',
    clickEventAction: 'footer_recommended'
  });

  const handleItemClick = (itemName: string) => {
    logEvent.click(itemName, 'footer', 'other');
  };

  const getFooterLinks = () => {
    return Object.entries(footerLinks).map((value, index) => {
      return (
        <FooterTitles key={`${value[0]}, ${index}`} data-test="footer-section">
          <h4>{t(mappedFooterTitles.get(value[0])?.name ?? '')}</h4>
          {getFooterItems(
            value[1],
            mappedFooterTitles.get(value[0])?.link ?? '/',
            mappedFooterTitles.get(value[0])?.clickEventAction as string
          )}
        </FooterTitles>
      );
    });
  };

  const getMobileFooterLinks = () => {
    return Object.entries(footerLinks).map((value, index) => {
      const isActive = index === activeIndex;
      const link = mappedFooterTitles.get(value[0])?.link ?? '/';
      return (
        <MobileFooterTitles
          key={`${value[0]}, ${index}`}
          onClick={() => {
            handleOptionClick(index);
          }}
          active={isActive}
        >
          <div className="footerContent">
            <h4>{t(mappedFooterTitles.get(value[0])?.name ?? '')}</h4>
            <ToggleArrowIcon
              styles={{ transform: isActive ? 'rotate(-180deg)' : '0' }}
            />
          </div>
          {getFooterItems(
            value[1],
            link,
            mappedFooterTitles.get(value[0])?.clickEventAction as string
          )}
        </MobileFooterTitles>
      );
    });
  };

  const getFooterItems = (
    items: FooterLinkEntity[],
    linkTo: string,
    clickEventAction: string
  ) => {
    return items.map((item, index) => (
      <Link
        key={`${item.name}, ${index}`}
        href={getLinkRef(item, linkTo)}
        prefetch={false}
        onClick={() => {
          handleItemClick(clickEventAction);
        }}
        className={!forceMobile ? 'footerTitleItem' : 'mobileFooterItem'}
        aria-label={item.name}
      >
        <h5 className={!forceMobile ? 'footerTitleItem' : 'mobileFooterItem'}>
          {item.name}
        </h5>
      </Link>
    ));
  };

  const getLinkRef = (
    item: FooterLinkEntity | PropertiesFooterLinkEntity,
    linkTo: string
  ): string => {
    if (linkTo === '/search') {
      if ('compoundSlug' in item) {
        return `/compound/${item.compoundSlug}/property/${item.slug}`;
      } else {
        throw new Error(
          "The provided item does not have a 'compoundSlug' property"
        );
      }
    }
    return `${linkTo}/${item.slug}`;
  };

  const footerProps = {
    footerLinks: footerLinks,
    getFooterItems: getFooterItems,
    getLinkRef: getLinkRef,
    getFooterLinks: forceMobile ? getMobileFooterLinks : getFooterLinks,
    handleItemClick: handleItemClick
  };

  return forceMobile ? (
    <FooterMobile {...footerProps} />
  ) : (
    <FooterDesktop {...footerProps} />
  );
};

const FooterTitles = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
  white-space: wrap;
  flex-direction: column;
  overflow-y: hidden;
  gap: 8px;

  span,
  h4 {
    padding: 8px 0px;
    font-family: materialBold;
    font-size: ${props => props.theme.font.sizes.s};
    color: ${props => props.theme.light.colors.primaryBlue};
  }

  .linksMore {
    font-family: materialRegular;
    font-size: ${props => props.theme.font.sizes.s};
    color: #015c9a;
    cursor: pointer;
    text-decoration-line: underline;
  }

  .footerTitleItem {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${props => props.theme.light.colors.darkGrey};
    font-family: materialRegular;
    font-size: ${props => props.theme.font.sizes.s};
    transition: all 0.4s ease;
    &:hover {
      color: ${props => props.theme.light.colors.primaryBlue};
      cursor: pointer;
    }
  }
`;
type MobileFooterTitlesProps = {
  active: boolean;
};

const MobileFooterTitles = styled.div<MobileFooterTitlesProps>`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
  white-space: wrap;
  flex-direction: column;
  width: 100%;
  height: auto;
  gap: 12px;
  overflow-y: hidden;

  .footerContent {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;

    svg {
      path {
        stroke: ${props => props.theme.light.colors.primaryBlue};
      }
    }
  }

  span,
  h4 {
    padding: 4px 0px;
    font-family: materialBold;
    font-size: ${props => props.theme.font.sizes.s};
    color: ${props => props.theme.light.colors.primaryBlue};
  }

  .linksMore {
    font-family: materialRegular;
    font-size: ${props => props.theme.font.sizes.s};
    color: ${props => props.theme.light.colors.primaryBlue};
    cursor: pointer;
    text-decoration-line: underline;
  }

  .mobileFooterItem {
    font-family: materialMedium;
    font-size: ${props => props.theme.font.sizes.s};
    color: ${props => props.theme.light.colors.black};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: ${props => (props.active ? 'block' : 'none')};
  }
`;

export default FooterContainer;
