import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { isEqual } from '../../../../../helpers/isEqual';
import { useEffect, useState } from 'react';
import SearchMenu from '../menu-select-filter/SearchMenu';
import { SearchFiltersTypes } from '../../../../../reducers/search-filters/reducer';
import { logEvent } from '../../../../../new-lib/analytics';

interface ContainerProps {
  filterOptions: any;
  filterName: string;
  handleSelect: (value: any) => void;
  selectedValues: number[] | { sort_by: string; direction: string };
  withSeeMore?: boolean;
  isNawyNowSelected?: boolean;
  singleSelect?: boolean;
}

const CheckboxFilterPresentation = ({
  filterOptions,
  filterName,
  handleSelect,
  selectedValues,
  withSeeMore,
  isNawyNowSelected,
  singleSelect
}: ContainerProps) => {
  const { t } = useTranslation('common');
  const [popupOpen, setPopupOpen] = useState(false);
  const filters = withSeeMore ? filterOptions.slice(0, 3) : filterOptions;
  const handleSeeMore = () => {
    setPopupOpen(!popupOpen);
  };
  const isRadioChecked = (option: any) => {
    return filterName === SearchFiltersTypes.SORT_BY
      ? isEqual(selectedValues, {
          order_by: option.value,
          order_direction: option.options.direction
        })
      : isEqual(selectedValues, option.value);
  };

  const extraParams = (option: any) => {
    switch (filterName) {
      case 'areas':
        return {
          area_id: option.value,
          area_name: option.options.displayedValue,
          count_areas: Array.isArray(selectedValues) ? selectedValues.length : 0
        };
      case 'developers':
        return {
          dev_id: option.value,
          dev_name: option.options.displayedValue,
          count_devs: Array.isArray(selectedValues) ? selectedValues.length : 0
        };
      case 'amenities':
        return {
          amenity_name: option.options.displayedValue
        };
      default:
        return {};
    }
  };
  return (
    <Container className="checkbox-filter-options">
      {filters?.map((option: any) => {
        return (
          <div
            key={option.value + option.options.direction}
            className="input-container"
          >
            <input
              type="checkbox"
              name={filterName}
              id={option.options.displayedValue}
              onChange={e => {
                e.stopPropagation();
                handleSelect(option);
                logEvent.click(
                  `${filterName}_filter`,
                  'search_page',
                  'filters',
                  extraParams(option)
                );
              }}
              disabled={
                isNawyNowSelected &&
                filterName === SearchFiltersTypes.DELIVERY_DATE
              }
              checked={
                singleSelect
                  ? isRadioChecked(option)
                  : Array.isArray(selectedValues) &&
                    selectedValues.includes(option.value)
              }
            />
            <label htmlFor={option.options.displayedValue}>
              {t(option.options.displayedValue)}
            </label>
          </div>
        );
      })}
      {withSeeMore && (
        <button onClick={handleSeeMore} className="see-more">
          {t('see_more')}{' '}
        </button>
      )}
      {popupOpen && (
        <SearchMenu
          filterName={filterName}
          options={filterOptions}
          type={filterName}
          setAreasDevelopersMenu={setPopupOpen}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .input-container {
    display: flex;
    align-items: center;
    gap: 8px;
    input[type='checkbox'],
    input[type='radio'] {
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
      width: 16px;
      height: 16px;
      border-radius: 4px;
      cursor: pointer;
      transform: translateY(-0.075em);
      &:not(:checked) {
        border: 1px solid #d3dbe6;
        background-color: #f7f9fd;
      }
      &:checked {
        background-color: #015c9a;
      }

      &::before {
        content: '';
        min-width: 13px;
        min-height: 13px;
        border-radius: 4px;
        transform: scale(0);
        transition: 0.2s transform ease-in-out;
      }

      &:checked::before {
        border: none;
        background-color: #f7f9fd;
        transform: scale(1);
        transform-origin: bottom left;
        clip-path: polygon(40% 78%, 88% 17%, 95% 23%, 42% 90%, 8% 62%, 14% 56%);
      }
      &:disabled {
        &:not(:checked) {
          background-color: #dddddd;
        }
      }
    }
    input[type='radio'] {
      border-radius: 50%;
      border: 1px solid #d3dbe6;
      background-color: #f7f9fd;
      &:checked {
        padding: 1px;
        background-color: #f7f9fd;
      }
      &:checked::before {
        border-radius: 50%;
        border: none;
        background-color: #015c9a;
        transform: scale(0.8);
        transform-origin: center;
        clip-path: none;
      }
    }
    label {
      cursor: pointer;
      text-transform: capitalize;
      font-size: 14px;
      color: #5b6573;
      line-height: 150%;
      font-family: materialMedium;
    }
  }

  .see-more {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4px;
    background: none;
    border: none;
    outline: none;
    color: #1e6dac;
    text-decoration: underline;
    font-size: 12px;
    font-family: materialRegular;
    cursor: pointer;
  }
`;

export default CheckboxFilterPresentation;
