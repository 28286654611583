import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import Breadcrumbs from '../../../new-components/Breadcrumbs';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';
import {
  SearchContext,
  SearchContextInterface
} from '../../search-new/context/SearchContext';
import { SearchFiltersActionTypes } from '../../../reducers/search-filters/actions';
import { SearchFiltersTypes } from '../../../reducers/search-filters/reducer';
import { EntityOffer } from '../../../new-lib/types/entity';
const DeveloperView = dynamic(() => import('./DeveloperView'));
const MobileDeveloperView = dynamic(() => import('./MobileDeveloperView'));

interface DeveloperProps {
  developer: any;
  offers: EntityOffer[];
  launches: any;
  filterOptions: any;
}
export const getDeveloperBreadcrumbsItems = (developer: any) => {
  const breadcrumbItems = [
    {
      displayedName: developer.name,
      path: `/developer/${developer.all_slugs.en}`
    }
  ];
  return breadcrumbItems;
};
const getDeveloperBreadcrumbs = (developer: any) => {
  return <Breadcrumbs itemsToShow={getDeveloperBreadcrumbsItems(developer)} />;
};

const Developer = ({
  developer,
  offers,
  launches,
  filterOptions
}: DeveloperProps) => {
  const isMobile = useSelector((state: any) => state.app.isMobile);
  const { asPath } = useRouter();
  const { searchFiltersDispatch } = useContext(
    SearchContext
  ) as SearchContextInterface;

  useEffect(() => {
    searchFiltersDispatch({
      type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
      payload: {
        name: SearchFiltersTypes.COMPOUNDS,
        value: []
      }
    });
    searchFiltersDispatch({
      type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
      payload: {
        name: SearchFiltersTypes.DEVELOPERS,
        value: [developer.id]
      }
    });
  }, [asPath]);

  return isMobile ? (
    <MobileDeveloperView
      developer={developer}
      offers={offers}
      launches={launches}
      filterOptions={filterOptions}
      breadcrumbs={getDeveloperBreadcrumbs(developer)}
    />
  ) : (
    <DeveloperView
      offers={offers}
      developer={developer}
      launches={launches}
      filterOptions={filterOptions}
      breadcrumbs={getDeveloperBreadcrumbs(developer)}
    />
  );
};
export default Developer;
