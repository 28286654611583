import Cookies from 'universal-cookie';

export const getImagePath = image_url => {
  if (!image_url) {
    return '/assets/imgs/broken.jpg';
  }

  if (image_url && image_url.includes('http')) {
    return image_url;
  }
  return process.env.NEXT_PUBLIC_IMAGE_DOMAIN + image_url;
};

export const objToQuery = obj => {
  const cookies = new Cookies();
  const arr = [];
  arr.push(`token=${cookies.get('token')}`);
  for (const [key, value] of Object.entries(obj)) {
    if (!value) continue;
    arr.push(`${key}=${value}`);
  }
  return arr.join('&');
};

export const commifyNumber = x => {
  if (!x) {
    return '';
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const unCommifyNumber = x => {
  return x.replace(/\,/g, '');
};

export const getReadyBy = (minReadyBy, maxReadyBy) => {
  let thisYear = new Date().getFullYear();
  minReadyBy = minReadyBy && minReadyBy.split('-')[0];
  maxReadyBy = maxReadyBy && maxReadyBy.split('-')[0];
  if (
    (minReadyBy && minReadyBy < thisYear) ||
    (maxReadyBy && maxReadyBy < thisYear)
  ) {
    return thisYear;
  }
  if (minReadyBy) {
    return minReadyBy;
  }
  if (maxReadyBy) {
    return maxReadyBy;
  }
  return null;
};

export const getPageLocation = currentUrl => {
  let ans;
  const pages = [
    'property',
    'compound',
    'search',
    'how-it-works',
    'favorites',
    'move-now',
    'top-compounds',
    'prime-compounds',
    'real-estate-360',
    '/',
    ''
  ];
  const res = currentUrl.split('/');
  for (let i = 0; i < pages.length; ++i) {
    if (res.includes(pages[i])) {
      if (pages[i] === '' || pages[i] === '/') ans = 'home';
      else ans = pages[i];
      break;
    }
  }
  return ans;
};
