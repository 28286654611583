import { Pagination } from './api.types';
import { newApi } from './api.instance';
import { DropdownOptionDto } from '../../features/search-new/dtos/dropdown-option.dto';
import { AreaLaunchDto } from '../dtos/areas/area-launch.dto';

export class AreasApis {
  public static list = async (pagination: Pagination, language?: string) => {
    return newApi({ ssrLocale: language })
      .get(`/v1/areas?page=${pagination.page}&pageSize=${pagination.pageSize}`)
      .then(({ data }) => data);
  };

  public static listAll = async (
    language?: string
  ): Promise<DropdownOptionDto[]> => {
    return newApi({ ssrLocale: language })
      .get(`/v1/areas/all`)
      .then(({ data }) => {
        return (
          data
            ?.map((area: any) => ({
              value: area.id,
              options: { displayedValue: area.name }
            }))
            .filter((area: any) => area.value && area.options.displayedValue) ??
          [] ??
          []
        );
      });
  };

  public static getSubAreas = async (ids: number[], language?: string) => {
    return newApi({ ssrLocale: language })
      .get(`/v1/areas/sub-areas`, { params: { ids } })
      .then(({ data }) => data);
  };

  public static getNeighboringAreas = async (
    ids: number[],
    language?: string
  ) => {
    return newApi({ ssrLocale: language })
      .get(`/v1/areas/neighboring-areas`, { params: { ids } })
      .then(({ data }) => data);
  };

  public static getAreaLaunches = async (
    id: number,
    language?: string
  ): Promise<AreaLaunchDto[]> => {
    return newApi({ ssrLocale: language })
      .get(`/v1/areas/${id}/launches`)
      .then(({ data }) => data);
  };
}
