import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { InputOption } from '../../../../../new-lib/types/search-filters';
import {
  SearchFiltersActions,
  SearchFiltersActionTypes
} from '../../../../../reducers/search-filters/actions';
import {
  SearchFiltersInterface,
  SearchFiltersTypes
} from '../../../../../reducers/search-filters/reducer';
import SliderPresentation from './SliderPresentation';
import { updateQuery } from '../../../../../helpers/common';
import { logEvent } from '../../../../../new-lib/analytics';

type SliderContainerProps = {
  searchFiltersState: SearchFiltersInterface;
  searchFiltersDispatch: React.Dispatch<SearchFiltersActions>;
  filterName: SearchFiltersTypes.PROPERTY_TYPES | SearchFiltersTypes.AMENITIES;
  filterTypes: InputOption[];
  options: [{ id: number; count: number }];
  withCountDisplay: boolean;
  sliderLocation: string;
};

export type SliderOption = {
  id: number;
  icon: string;
  name: string;
  count?: number;
};

const SliderContainer = ({
  searchFiltersState,
  searchFiltersDispatch,
  filterName,
  filterTypes,
  options,
  withCountDisplay,
  sliderLocation
}: SliderContainerProps) => {
  const router = useRouter();
  const [sliderOptions, setSliderOptions] = useState<SliderOption[]>([]);

  useEffect(() => {
    if (options) getSliderOptions();
  }, [options]);

  const checkOptionCount = (id: number, count: number) => {
    if (checkOptionSelected(id)) {
      return true;
    }
    if (count > 0) return true;
    return false;
  };

  const getSliderOptions = () => {
    let formattedOptions: SliderOption[] = [];
    options.forEach(option => {
      let typeIndex = filterTypes.findIndex(type => type.value == option.id);
      if (typeIndex == -1) return;
      withCountDisplay &&
        checkOptionCount(option.id, option.count) &&
        formattedOptions.push({
          ...option,
          icon: filterTypes[typeIndex]?.options.image,
          name: filterTypes[typeIndex]?.options.displayedValue
        });

      !withCountDisplay &&
        formattedOptions.push({
          id: option.id,
          icon: filterTypes[typeIndex]?.options.image,
          name: filterTypes[typeIndex]?.options.displayedValue
        });
    });
    setSliderOptions(formattedOptions);
  };

  const optionHandler = (option: SliderOption) => {
    let selectedValues = searchFiltersState.searchFilters[filterName];

    if (selectedValues.includes(option.id)) {
      selectedValues = selectedValues.filter(value => value != option.id);
    } else {
      selectedValues = [...selectedValues, option.id];
    }

    searchFiltersDispatch({
      type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
      payload: {
        name: filterName,
        value: selectedValues
      }
    });

    logEvent.search(
      `filter_property_types`,
      sliderLocation,
      'filter',
      searchFiltersState
    );
    if (selectedValues.length > 0) {
      updateQuery(router, [
        { name: filterName, value: selectedValues.toString() }
      ]);
    } else updateQuery(router, undefined, [filterName]);
  };

  const checkOptionSelected = (optionId: number) => {
    return searchFiltersState.searchFilters[filterName].includes(optionId);
  };
  return (
    <SliderPresentation
      sliderOptions={sliderOptions}
      optionHandler={optionHandler}
      checkOptionSelected={checkOptionSelected}
    />
  );
};
export default SliderContainer;
