const intialState = {
  showOpenApp: false,
  referer: '',
  userId: null,
  eventId: null,
  isMobile: null,
  experimentVariant: null,
  webview: null,
  sahelPopup: false,
  sahelModal: false,
  userLocation: null,
  translatedUrl: null
};

const appReducer = (state = intialState, action) => {
  switch (action.type) {
    case 'show_open_app':
      return {
        ...state,
        showOpenApp: true
      };

    case 'hide_open_app':
      return {
        ...state,
        showOpenApp: false
      };

    case 'set_referer':
      return {
        ...state,
        referer: action.payload
      };
    case 'set_user':
      return {
        ...state,
        userId: action.payload
      };
    case 'set_event':
      return {
        ...state,
        eventId: action.payload
      };
    case 'set_is_mobile':
      return {
        ...state,
        isMobile: action.payload
      };
    case 'set_experiment':
      return {
        ...state,
        experimentVariant: action.payload
      };
    case 'set_webview':
      return {
        ...state,
        webview: action.payload
      };
    case 'set_sahelPopup':
      return {
        ...state,
        sahelPopup: action.payload
      };
    case 'set_sahelModal':
      return {
        ...state,
        sahelModal: action.payload
      };
    case 'set_user_location':
      return {
        ...state,
        userLocation: action.payload
      };
    case 'set_translated_path':
      return {
        ...state,
        translatedPath: action.payload
      };

    default:
      return { ...state };
  }
};

export default appReducer;
