import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { logEvent } from '../new-lib/analytics';
import { getPageLocation } from '../helpers/common';
import { useSelector } from 'react-redux';

const useCookieConsent = () => {
  const { asPath } = useRouter();

  const [showConsent, setShowConsent] = useState(false);

  const userLocation = useSelector((state: any) => state.app.userLocation);

  const confirmDisclaimer = (actionName: string) => {
    setShowConsent(false);
    localStorage.setItem('userConsentPopup', 'true');
    localStorage.setItem('userConsent', 'true');
    logEvent.click(`${actionName}_consent`, getPageLocation(asPath), 'other');
  };

  const rejectConsent = () => {
    setShowConsent(false);
    localStorage.setItem('userConsentPopup', 'true');
    localStorage.setItem('userConsent', 'false');
  };

  useEffect(() => {
    if (userLocation) {
      setShowConsent(!(localStorage.getItem('userConsentPopup') === 'true'));
    }
  }, [userLocation]);

  return {
    showConsent,
    isEUUser: userLocation?.isEUUser,
    confirmDisclaimer,
    rejectConsent
  };
};

export default useCookieConsent;
