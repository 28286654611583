import ImageWrapper from '../ImageWrapper';

const YoutubeIcon = () => {
  return (
    <ImageWrapper
      src={`/assets/icons/new/youtube-icon.svg`}
      alt={'youtube-icon'}
      width={30}
      height={30}
    />
  );
};
export default YoutubeIcon;
