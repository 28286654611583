import { MapCoords } from '../../features/map/types';

export interface Compound {
  id: number;
  name: string;
  slug: string;
  area_id: string;
  all_slugs: { ar: string; en: string };
  amenities: [];
  area: any; // to be updated with the area type
  child_compounds: [];
  compound_images: [];
  cover_image_path: string;
  currency: string;
  description: string;
  developer: any; // to be updated with the developer type
  developer_id: number;
  display_name: string;
  features: [];
  has_offer: boolean;
  is_super: boolean;
  lat: number;
  long: number;
  max_price: number;
  min_price: number;
  resale_min_price: number;
  offers: [];
  compound_offers: [];
  parent_compound?: {
    id?: number;
    location_point?: number[];
    name?: string;
    polygon_points?: MapCoords[][] | null;
  };
  parent_compound_id?: number;
  payment_plans: PaymentInterface[];
  phases: [];
  properties_count: number;
  map_path: string;
  video: { url: string };
  nawy_organization_id?: number | null;
  on_sahel_map?: boolean;
  polygon_points?: MapCoords[][] | null;
  primary_min_price: number;
}

export type Entity = {
  id: number;
  slug: string;
  name: string;
};

export interface PaymentInterface {
  id: number;
  name: string;
  description: string;
  installment_type: { id: number; name: string };
  equal_installments: { value: number };
  down_payment: { value: number; percent: number };
  cash_discount: { value: number; percent: number };
  years: number;
  months: number;
  original_payment_plan: PaymentInterface;
  years_remaining: number;
  price: number;
  currency: string;
}

export type EntityOffer = {
  id: number;
  nawy_exclusive: boolean;
  description: string;
  compound: Entity;
  developer: Entity;
  discount: number;
  offer: PaymentInterface;
  original_payment_plan: PaymentInterface;
};

export interface Image {
  id: number;
  cover_photo: boolean;
  image_name: string;
  image_path: string;
  low_quality_image_name: string;
  low_quality_image_path: string;
  medium_quality_image_name: string;
  medium_quality_image_path: string;
}

export enum EntityDetailsTypes {
  MAP = 'map',
  FLOOR_PLAN = 'floor_plan',
  GALLERY = 'gallery',
  MASTER_PLAN = 'master_plan'
}

export enum Availability {
  InStock = 'InStock',
  OutOfStock = 'OutOfStock'
}
