import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { useContext, useEffect, useState } from 'react';
import {
  SearchContext,
  SearchContextInterface
} from '../../../context/SearchContext';
import { SearchFiltersActionTypes } from '../../../../../reducers/search-filters/actions';
import { SearchFiltersTypes } from '../../../../../reducers/search-filters/reducer';
import { useRouter } from 'next/router';
import { updateQuery } from '../../../../../helpers/common';
import { logEvent } from '../../../../../new-lib/analytics';
import SelectFilterContainer from '../select-filter/SelectFilterContainer';
import { FilterStyles } from '../FilterWrapper';
import InputValue from '../range-filter/InputValue';
import { unCommifyNumber } from '../../../../../lib/helpers';

interface ContainerProps {
  filterOptions: any;
  filterType: {
    down_payment: SearchFiltersTypes.DOWN_PAYMENT;
    monthly_installments: SearchFiltersTypes.MONTHLY_INSTALLMENTS;
    installment_years: SearchFiltersTypes.INSTALLMENT_YEARS;
  };
}

const PaymentFilter = ({ filterOptions, filterType }: ContainerProps) => {
  const { t } = useTranslation('search');
  const { searchFiltersState, searchFiltersDispatch } = useContext(
    SearchContext
  ) as SearchContextInterface;
  const [downPayment, setDownPayment] = useState<number>(0);
  const [monthlyInstallments, setMonthlyInstallments] = useState<number>(0);
  const router = useRouter();

  const applyFilter = (filterType: any) => {
    const filterValue =
      filterType === SearchFiltersTypes.DOWN_PAYMENT
        ? downPayment
        : monthlyInstallments;

    if (filterValue > 0) {
      searchFiltersDispatch({
        type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
        payload: {
          name: filterType,
          value: filterValue
        }
      });

      logEvent.search(
        `filter_${filterType}`,
        'filtersMenu',
        'filter',
        searchFiltersState,
        {
          [filterType]: filterValue
        }
      );
      updateQuery(router, [{ name: filterType, value: filterValue }]);
    } else {
      searchFiltersDispatch({
        type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
        payload: {
          name: filterType,
          value: undefined
        }
      });

      updateQuery(router, undefined, [filterType]);
    }
  };
  const onChange = (value: any, filterType: SearchFiltersTypes) => {
    const newValue = value.replace(/[^0-9]/g, '');
    if (filterType === SearchFiltersTypes.DOWN_PAYMENT) {
      setDownPayment(newValue);
    } else if (filterType === SearchFiltersTypes.MONTHLY_INSTALLMENTS) {
      setMonthlyInstallments(newValue);
    }
  };

  useEffect(() => {
    setDownPayment(searchFiltersState.searchFilters.down_payment || 0);
    setMonthlyInstallments(
      searchFiltersState.searchFilters.monthly_installments || 0
    );
  }, [searchFiltersState]);
  return (
    <Container>
      <div className="inputs-container">
        <InputValue
          prefix="EGP"
          applyFilter={() => applyFilter(SearchFiltersTypes.DOWN_PAYMENT)}
          onChange={(e: any) =>
            onChange(
              unCommifyNumber(e.target.value),
              SearchFiltersTypes.DOWN_PAYMENT
            )
          }
          value={downPayment}
          placeholder={t('down_payment')}
        />
        <InputValue
          prefix="EGP"
          applyFilter={() =>
            applyFilter(SearchFiltersTypes.MONTHLY_INSTALLMENTS)
          }
          onChange={(e: any) =>
            onChange(
              unCommifyNumber(e.target.value),
              SearchFiltersTypes.MONTHLY_INSTALLMENTS
            )
          }
          value={monthlyInstallments}
          placeholder={t('monthly_installments')}
        />
      </div>

      <span className="filter-title">{t('installment_years')}</span>
      <SelectFilterContainer
        filterOptions={filterOptions.installment_years}
        filterType={filterType.installment_years}
        filterName={t('payment')}
        filterStyle={FilterStyles.NUMBER_SELECT}
        maxSelectNumber={6}
      />
    </Container>
  );
};

const Container = styled.div`
  .inputs-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-bottom: 8px;
  }
  .filter-title {
    text-transform: capitalize;
    font-size: 14px;
    color: #1a2028;
    line-height: 150%;
    font-family: materialMedium;
    display: block;
    margin-bottom: 8px;

    @media screen and (max-width: ${props =>
        props.theme.breakPoints.mobile.max}) {
      font-size: 17px;
      font-family: materialBold;
    }
  }
`;

export default PaymentFilter;
